import { createTemplateDetails } from "components/reusable/template";
import { GenericPass } from "components/passes/genericPass";
import { useRetrieveGenericPassTemplateQuery, useDeleteGenericPassMutation, useDeleteGenericPassTemplateMutation } from "services/genericPasses";


export const GenericPassTemplateDetails = createTemplateDetails({
  TemplateRenderer: GenericPass,
  retrieveHook: useRetrieveGenericPassTemplateQuery,
  deletePassHook: useDeleteGenericPassMutation,
  deleteTemplateHook: useDeleteGenericPassTemplateMutation,
  baseUrl: '/generic-passes/',
  i18nNamespace: 'genericPass',
})
  