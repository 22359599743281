import i18n from 'i18n';

const common = {
    ru: {
        "Loading": "Загрузка",
        "LOADING": "Загрузка",
        "noAvailablePasses": "Нет доступных талонов",
        "BoardingPass": "Посадочный талон",
        "generateNew": "Создать",
        "download": "Скачать",
        "createdPass": "Созданный талон",
        "createdPasses": "Созданные талоны",
        // Creator
        "CreateBoardingPassTemplate": "Создать шаблон посадочного талона",
        "allowAnonymous": "Разрешить анонимным пользователям",
        "transitType": "Тип транзита",
        "changeField": "Изменить поле",
        "addField": "Добавить поле",
        "allowModification": "Разрешить изменение",
        "alignment": "Выравнивание",
        "Left": "Слева",
        "Center": "По центру",
        "Right": "Справа",
        "Justified": "Упрощенно",
        "Natural": "Натурально",
        "key": "Ключ",
        "label": "Ярлык",
        "value": "Значение",
        "changeMessage": "Сообщение при изменении поля",
        "needToAdd": "Необходимо добавить",
        "cannotAddMore": "Невозможно добавить больше",
        "cannotRemoveMore": "Невозможно удалить",
        "outOf": "из",
        "locationsAdded": "локаций добавлено",
        "proximityUuid": "UUID радиомаяка",
        "major": "Major",
        "minor": "Minor",
        "relevantText": "Релевантный текст",
        "addLocation": "Добавить локацию",
        "altitude": "Высота",
        "latitude": "Широта",
        "longitude": "Долгота",
        "serialNumber": "Серийный номер",
        "PDF417": "PDF417",
        "QR": "QR",
        "Aztec": "Aztec",
        "Code128": "Code128",
        "messageSource": "Источник содержания",
        "customKey": "Собственный ключ",
        "messageSourceKey": "Ключ содержания",
        "useAsAlt": "Использовать содержание для подписи",
        "altText": "Альтернативный текст",
        "AIR": "Самолет",
        "TRAIN": "Поезд",
        "BUS": "Автобус",
        "BOAT": "Лодка",
        "GENERIC": "Иное",
        "verboseName": "Название",
        "description": "Описание",
        "expirationDate": "Срок годности",
        "relevantDate": "Дата и время события",
        "appLaunchUrl": "Ссылка на приложение",
        "associatedStoreID": "Ассоциированный с картой ID в App Store",
        "logoText": "Текст возле логотипа",
        "maxDistance": "Дистанция срабатывания",
        "logoImage": "Логотип",
        "iconImage": "Иконка",
        "footerImage": "Изображение в футере",
        "foregroundColor": "Цвет содержания",
        "labelColor": "Цвет ярлыков",
        "backgroundColor": "Цвет фона",
        "fields": "Поля",
        "originField": "Поле отправления",
        "destinationField": "Поле прибытия",
        "headerFields": "Поля в шапке",
        "secondaryFields": "Вторичные поля",
        "auxiliaryFields": "Дополнительные поля",
        "backFields": "Поля на задней стороне карты",
        "ibeaconList": "Список радиометок",
        "locationList": "Список локаций",
        "barcodeList": "Список баркодов",
        "generatePass": "Сгенерировать",
        "fieldsAdded": "полей добавлено",
        "addIBeacon": "Добавить радиометку",
        "barcodeFormat": "Формат баркода",
        "format": "Формат",
        "create": "Создать",
        // Table
        "update": "Обновить",
        "delete": "Удалить",
        "rowsPerPage": "Строк на странице",
        "prevPage": "Предыдущая страница",
        "nextPage": "Следующая страница",
        "id": "ID",
        "updatedAt": "Обновлено",
        "actions": "Действия",
        "noCreatedPasses": "Нет созданных талонов",
        "sendPush": "Отправить PUSH",
        // edit template
        "uploadImage": "Выбрать изображение",
        "ibeaconsAdded": "радиометок добавлено",
        "barcodesAdded": "баркодов добавлено",
        // template render back
        "noBackFields": "Нет полей на обратной стороне",

    },
    en: {
        "Loading": "Loading",
        "LOADING": "Loading",
        "noAvailablePasses": "No available passes",
        "BoardingPass": "Boarding pass",
        "generateNew": "Generate",
        "download": "Download",
        "createdPass": "Created pass",
        "createdPasses": "Created passes",
        // Creator
        "CreateBoardingPassTemplate": "Создать шаблон посадочного талона",
        "allowAnonymous": "Allow anonymous generation",
        "transitType": "Transit type",
        "changeField": "Change field",
        "addField": "Add field",
        "allowModification": "Allow modification",
        "alignment": "Alignment",
        "Left": "Left",
        "Center": "Center",
        "Right": "Right",
        "Justified": "Justified",
        "Natural": "Natural",
        "key": "Key",
        "label": "Label",
        "value": "Value",
        "changeMessage": "Message on change",
        "needToAdd": "Need to add",
        "cannotAddMore": "You can't add more",
        "cannotRemoveMore": "You can't remove more",
        "outOf": "out of",
        "locationsAdded": "locations added",
        "proximityUuid": "Proximity UUID",
        "major": "Major",
        "minor": "Minor",
        "relevantText": "Relevant text",
        "addLocation": "Add location",
        "altitude": "Altitude",
        "latitude": "Latitude",
        "longitude": "Longitude",
        "serialNumber": "Serial number",
        "PDF417": "PDF417",
        "QR": "QR",
        "Aztec": "Aztec",
        "Code128": "Code128",
        "messageSource": "Source of message",
        "customKey": "Custom key",
        "messageSourceKey": "Key of message source",
        "useAsAlt": "Use message as alternative text",
        "altText": "Alternative text",
        "AIR": "Airplane",
        "TRAIN": "Train",
        "BUS": "Bus",
        "BOAT": "Boat",
        "GENERIC": "Other",
        "verboseName": "Verbose name",
        "description": "Description",
        "expirationDate": "Expiration date",
        "relevantDate": "Relevant date",
        "appLaunchUrl": "Application launch url",
        "associatedStoreID": "Associated store ID",
        "logoText": "Logo text",
        "maxDistance": "Maximum distance",
        "logoImage": "Logo image",
        "iconImage": "Icon image",
        "footerImage": "Footer image",
        "foregroundColor": "Foreground colour",
        "labelColor": "Label colour",
        "backgroundColor": "Background colour",
        "fields": "Fields",
        "originField": "Origin fields",
        "destinationField": "Destination fields",
        "headerFields": "Header fields",
        "secondaryFields": "Secondary fields",
        "auxiliaryFields": "Auxiliary fields",
        "backFields": "Back fields",
        "ibeaconList": "List of iBeacons",
        "locationList": "List of locations",
        "barcodeList": "List of Barcodes",
        "generatePass": "Generate",
        "fieldsAdded": "fields added",
        "addIBeacon": "Add iBeacon",
        "barcodeFormat": "Barcode format",
        "format": "Format",
        "create": "Create",
        // Table
        "edit": "Edit",
        "update": "Update",
        "delete": "Delete",
        "rowsPerPage": "Rows per page",
        "prevPage": "Previous page",
        "nextPage": "Next page",
        "id": "ID",
        "updatedAt": "Updated at",
        "actions": "Actions",
        "noCreatedPasses": "No created passes",
        "sendPush": "Send PUSH",
        // edit template
        "uploadImage": "Choose image",
        "ibeaconsAdded": "iBeacons added",
        "barcodesAdded": "barcodes added",
        // template render back
        "noBackFields": "No back fields",
    }
}
i18n
    .addResourceBundle('ru', 'pass', common.ru)
    .addResourceBundle('en', 'pass', common.en)