import './App.css';
import { Provider } from 'react-redux';
import { store } from './app/store';
import AppRouter from './components/router';
import './i18n';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function App() {
  return (
    <Provider store={store}>
      <AppRouter />
      <ToastContainer autoClose={2000} />
    </Provider>
  );
}

export default App;
