import React, { Dispatch, createContext, useContext, useState } from "react";
import { CreatedPass, ScenarioTemplate } from "types";


type ScenarioContext = {
  passTemplate: any;
  scenarioType: string | undefined;
  scenarioId: string | undefined;
  scenarioObject: ScenarioTemplate | null;
  setScenarioObject: Dispatch<ScenarioTemplate | null>;
  createdPass: CreatedPass | null;
  setCreatedPass: Dispatch<CreatedPass | null>;
};

export const ScenarioContext = createContext<ScenarioContext | null>(null);

export const ScenarioContextProvider = ({ children, scenarioId, scenarioType, passTemplate }: { children: React.ReactNode, scenarioId: string | undefined, scenarioType: string | undefined, passTemplate: any }) => {
  const [createdPass, setCreatedPass] = useState<CreatedPass | null>(null);
  const [scenarioObject, setScenarioObject] = useState<ScenarioTemplate | null>(null);

  return (
    <ScenarioContext.Provider value={{ scenarioType, scenarioId, scenarioObject, setScenarioObject, createdPass, setCreatedPass, passTemplate }}>
      {children}
    </ScenarioContext.Provider>
  );
};

export const useScenarioContext = () => {
  const context = useContext(ScenarioContext);
  if (!context) {
    throw new Error("Scenario.* must be rendered as a child of Scenario component.");
  }
  return context;
};