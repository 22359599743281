import { Membership, MembershipTemplate, MembershipTransaction, PaginatedResponse } from 'types';
import { API_URL } from 'constants/api';
import { api } from 'services/api';





const MEMBERSHIP_URL = `${API_URL}/scenarios/memberships`

// Define a service using a base URL and expected endpoints
export const membershipApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getMembership: builder.query<Membership, string>({
      query: (scenarioId) => `${MEMBERSHIP_URL}/${scenarioId}`,
    }),
    getMembershipTemplate: builder.query<MembershipTemplate, string>({
      query: (scenarioId) => `${MEMBERSHIP_URL}/templates/${scenarioId}`,
    }),
    getMembershipBySerial: builder.query<Membership, string>({
      query: (serial) => `${MEMBERSHIP_URL}/get_coupon_info/?serial_number=${serial}`,
      providesTags: (result, error, serial) => [{ type: 'Memberships', id: serial }],
    }),
    getMembershipTransactions: builder.query<PaginatedResponse<MembershipTransaction>, {sn: string, page?: number, size?: number}>({
      query: ({sn, page, size}) => `${MEMBERSHIP_URL}/transactions/?serial_number=${sn}&page=${page}&page_size=${size}`,
      providesTags: [{ type: 'MembershipTransactions', id: 'LIST' }],
    }),
    addVisit: builder.mutation<Membership, string>({
      query: (serial) => ({
        url: `${MEMBERSHIP_URL}/add_visit/?serial_number=${serial}`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, serial) => [{ type: 'Memberships', id: serial }, { type: 'CreatedPasses', id: serial }, { type: 'MembershipTransactions', id: 'LIST' }],
    }),
    removeVisit: builder.mutation<Membership, string>({
      query: (serial) => ({
        url: `${MEMBERSHIP_URL}/remove_visit/?serial_number=${serial}`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, serial) => [{ type: 'Memberships', id: serial }, { type: 'CreatedPasses', id: serial }, { type: 'MembershipTransactions', id: 'LIST' }],
    }),
    renewMembership: builder.mutation<Membership, string>({
      query: (serial) => ({
        url: `${MEMBERSHIP_URL}/renew_membership/?serial_number=${serial}`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, serial) => [{ type: 'Memberships', id: serial }, { type: 'CreatedPasses', id: serial }, { type: 'MembershipTransactions', id: 'LIST' }],
    }),
  }),
})

export const { useLazyGetMembershipBySerialQuery, useGetMembershipTemplateQuery, useRenewMembershipMutation, useGetMembershipQuery, useGetMembershipTransactionsQuery, useLazyGetMembershipTransactionsQuery, useAddVisitMutation, useRemoveVisitMutation } = membershipApiSlice;