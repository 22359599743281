import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/reusable/buttons';
import { useAddStampMutation, useRemoveAccumulativeMutation, useGetStampCouponTemplateQuery, useLazyGetCouponBySerialQuery } from 'services/scenarios';
import { useScenarioContext } from 'contexts/scenarioContext';
import { Table, TableBody, TableRow, TableCell } from 'components/reusable/tables';
import { create } from 'domain';
import { StampCoupon } from 'types';
import { toast } from 'react-toastify';
import styled from 'styled-components';


const StampCouponActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: #f5f5f5;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin: auto 0;

  button {
    margin: 0 10px;
  }
`;

const ErrorMessage = styled.p`
  color: red;

  &:before {
    content: "Error: ";
    font-weight: bold;
  }
`;


export const StampCouponActions = () => {

  const { createdPass, scenarioId } = useScenarioContext();
  const { t } = useTranslation();
  const [stampCoupon, setStampCoupon] = useState<StampCoupon | null>(null);
  const [addStamp, { isSuccess: isAddSuccess, isLoading: isAddLoading, isError: isAddError, data: addData }] = useAddStampMutation();
  const [removeAccumulative, { isSuccess: isRemoveSuccess, isLoading: isRemoveLoading, isError: isRemoveError, data: removeData }] = useRemoveAccumulativeMutation();
  const [getCouponBySerial, { isSuccess: isGetBySerialSuccess, isError: isGetError, data: stampCouponData }] = useLazyGetCouponBySerialQuery();

  useEffect(() => {
    if (isAddSuccess) {
      toast.success("Stamp added");
    } else if (isAddError) {
      toast.error("Adding stamp is failed", { autoClose: 5000 });
    }
    if (createdPass?.serial_number && !stampCoupon) {
      getCouponBySerial(String(createdPass?.serial_number));
    }
  }, [isAddSuccess, isAddError, createdPass?.serial_number]);

  
  useEffect(() => {
    if (isRemoveSuccess) {
      toast.success("Accumulative reward removed");
    } else if (isRemoveError) {
      toast.error("Removing reward is failed", { autoClose: 5000 });
    }
  }, [isRemoveSuccess, isRemoveError]);

  useEffect(() => {
    if (isGetBySerialSuccess) {
      setStampCoupon(stampCouponData as StampCoupon);
      // toast.success("Pass found!");
    } else if (isGetError) {
      toast.error("Getting stamp coupon is failed", { autoClose: 5000 });
    }
  }, [isGetBySerialSuccess, isGetError]);

  return (
    <StampCouponActionsContainer>
      <h2>{t('stampCouponActions')}</h2>
      {!createdPass?.serial_number ? (
        <p>{t('noPassSelected')}</p>
      ) : (
        (scenarioId == stampCoupon?.template) ? (
          <>
            <ButtonContainer>
              <Button onClick={() => addStamp(String(createdPass?.serial_number))} disabled={!Boolean(createdPass?.serial_number) || isAddLoading}>{t('addStamp')}</Button>
              <Button onClick={() => removeAccumulative(String(createdPass?.serial_number))} disabled={!Boolean(createdPass?.serial_number) || isRemoveLoading}>{t('removeReward')}</Button>
            </ButtonContainer>
          </>
        ) : (
          <>
            <p>{t('wrongTemplateOrCardSelected')}</p>
          </>
        )
      )}
    </StampCouponActionsContainer>
  )
}


export const StampCouponState = () => {

  const { t } = useTranslation();
  const { scenarioId, scenarioObject, setScenarioObject } = useScenarioContext();

  const {data, isLoading, isSuccess} = useGetStampCouponTemplateQuery(String(scenarioId));

  useEffect(() => {
    if (isSuccess) {
      setScenarioObject(data);
    }
  }, [isSuccess]);

  return (
    <>
      <h2>{t('stampCouponState')}</h2>
      {isLoading && <p>{t('loading')}</p>}
      {isSuccess && (
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>{t('stampCouponName')}</TableCell>
              <TableCell>{scenarioObject?.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('stampCouponDescription')}</TableCell>
              <TableCell>{scenarioObject?.description}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('stampCouponStampCount')}</TableCell>
              <TableCell>{scenarioObject?.stamp_count}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('stampCouponRewardCount')}</TableCell>
              <TableCell>{scenarioObject?.max_accumulative_rewards}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('stampCouponAccumulative')}</TableCell>
              <TableCell>{scenarioObject?.accumulative_reward ? t('yes') : t('no')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('stampCouponCopyAvailable')}</TableCell>
              <TableCell>{scenarioObject?.available_for_copy ? t('yes') : t('no')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('stampCouponRewardExpirable')}</TableCell>
              <TableCell>{scenarioObject?.is_reward_expirable ? t('yes') : t('no')}</TableCell>
            </TableRow>
            {scenarioObject?.is_reward_expirable && (
              <TableRow>
                <TableCell>{t('stampCouponRewardExpDelta')}</TableCell>
                <TableCell>{scenarioObject?.reward_expiration_delta}</TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>{t('stampCouponStampExpirable')}</TableCell>
              <TableCell>{scenarioObject?.is_stamp_expirable ? t('yes') : t('no')}</TableCell>
            </TableRow>
            {scenarioObject?.is_stamp_expirable && (
              <TableRow>
                <TableCell>{t('stampCouponStampExpDelta')}</TableCell>
                <TableCell>{scenarioObject?.stamp_expiration_delta}</TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>{t('stampCouponAccumulative')}</TableCell>
              <TableCell>{scenarioObject?.accumulative_reward ? t('yes') : t('no')}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </>
  )
}