import { ScenarioTemplate, StampCoupon, StampCouponTransaction, PaginatedResponse } from 'types';
import { API_URL } from 'constants/api';
import { api } from 'services/api';





const STAMP_COUPON_URL = `${API_URL}/scenarios/stamp_coupons`

// Define a service using a base URL and expected endpoints
export const stampCouponApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getStampCoupon: builder.query<StampCoupon, string>({
      query: (scenarioId) => `${STAMP_COUPON_URL}/${scenarioId}`,
    }),
    getStampCouponTemplate: builder.query<ScenarioTemplate, string>({
      query: (scenarioId) => `${STAMP_COUPON_URL}/templates/${scenarioId}`,
    }),
    getCouponBySerial: builder.query<StampCoupon, string>({
      query: (serial) => `${STAMP_COUPON_URL}/get_coupon_info/?serial_number=${serial}`,
      providesTags: (result, error, serial) => [{ type: 'StampCoupons', id: serial }],
    }),
    getCouponTransactions: builder.query<PaginatedResponse<StampCouponTransaction>, {sn: string, page?: number, size?: number}>({
      query: ({sn, page, size}) => `${STAMP_COUPON_URL}/transactions/?serial_number=${sn}&page=${page}&page_size=${size}`,
      providesTags: [{ type: 'StampCouponTransactions', id: 'LIST' }],
    }),
    addStamp: builder.mutation<StampCoupon, string>({
      query: (serial) => ({
        url: `${STAMP_COUPON_URL}/add_stamp/?serial_number=${serial}`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, serial) => [{ type: 'StampCoupons', id: serial }, { type: 'CreatedPasses', id: serial }, { type: 'StampCouponTransactions', id: 'LIST' }],
    }),
    removeAccumulative: builder.mutation<StampCoupon, string>({
      query: (serial) => ({
        url: `${STAMP_COUPON_URL}/remove_accumulative/?serial_number=${serial}`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, serial) => [{ type: 'StampCoupons', id: serial }, { type: 'CreatedPasses', id: serial }, { type: 'StampCouponTransactions', id: 'LIST' }],
    }),
  }),
})

export const { useGetStampCouponQuery, useAddStampMutation, useRemoveAccumulativeMutation, useGetCouponBySerialQuery, useGetStampCouponTemplateQuery, useLazyGetCouponBySerialQuery, useLazyGetCouponTransactionsQuery, useGetCouponTransactionsQuery } = stampCouponApiSlice;