import React from 'react';
import { Typography } from '@mui/material';
import styled from 'styled-components';
import { NavBar } from 'components/navbar/navbar';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #ebebeb;
  color: #000000;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

// const Navbar = styled.nav`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 16px;
//   background-color: #333;
//   color: white;
// `;

const Logo = styled.h1`
  font-size: 24px;
  margin: 0;
`;

const ChildContainer = styled.div`
  flex: 1;
  padding: 16px;
`;


export default function Page({ children, title }) {
  return (
    <Container>
      <ChildContainer>
      {children}
      </ChildContainer>
    </Container>
  )
}


export function BackofficeContainer({ children }) {
  return (
    <Container>
      <NavBar />
      <ChildContainer>
        {children}
      </ChildContainer>
    </Container>
  );
}
