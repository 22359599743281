import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Typography, TextField, Checkbox, FormControlLabel, Slider } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CompactPicker } from 'react-color';
import { Field } from 'types';
import { selectRealm } from 'features/users/usersSlice';
import { useSelector } from 'react-redux';
import { FieldEdit, FieldListEdit, IBeaconListEdit, LocationListEdit, BarcodeListEdit } from 'components/passes/helpers';
import { Button } from 'components/reusable/buttons';
import { ImageInput, Input, InputGroup, Label } from 'components/reusable/inputs';
import { TemplateType } from 'interfaces';
import { Select, Option, Label as SelectLabel } from 'components/reusable/buttons';
import { InputsWrapper, TemplateBlock, TemplateRendererWrapper } from 'components/reusable/containers';


interface TemplateProps<T> {
  initialData: T;
  TemplateRenderer: any;
  successBaseUrl: string;
  i18nNamespace: string;
  createTemplateHook: () => any;
}


export const createTemplateComponent = <T extends TemplateType,>({ initialData, TemplateRenderer, i18nNamespace, successBaseUrl, createTemplateHook }: TemplateProps<T>) => {
  const CreateTemplate = () => {
    const { t } = useTranslation(i18nNamespace);
    const realm = useSelector(selectRealm);
    const navigate = useNavigate();

    const [inputs, setInputs] = useState<T>({
      ...initialData,
      owner: realm?.id,
    });
    const [create, result] = createTemplateHook();

    const handleSubmit = (e: any) => {
      e.preventDefault();
      create({...inputs,
        relevant_date: inputs.relevant_date?.toISOString(),
        expiration_date: inputs.expiration_date?.toISOString(),
      })
    };

    useEffect(() => {
      if(result.isSuccess) {
        navigate('/backoffice' + successBaseUrl, { replace: true });
      }
      if(realm?.id) {
        setInputs(values => ({...values, owner: realm?.id}));
      }
    }, [result.isSuccess, realm?.id])

    const handleChange = (event: any) => {
      setInputs(values => ({...values, [event.target.name]: event.target.value}));
    }

    const getBase64 = (file: any, cb: any) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
          cb(reader.result)
      };
      reader.onerror = function (error) {
          console.log('Error: ', error);
      };
    }

    const handleFileFieldChange = (file: any, filename: string | null) => {
      if (file) {
        getBase64(file, (result: string) => {
          setInputs(v => ({...v, [filename || "image.png"]: result}));
        });
      }
    }

    const setField = (key: string) => {
      return function(value: Field) {
        setInputs(values => ({...values, [key]: value}))
      }
    }

    const setFields = (key: string) => {
      return function(value: Field[]) {
        setInputs(values => ({...values, [key]: value}))
      }
    }

    const handleChecked = (event: any) => {
      const name = event.target.name;
      const value = event.target.checked;
      setInputs(values => ({...values, [name]: value}));
    }

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Typography gutterBottom variant="h5" component="h2">{t('CreateTemplate')}</Typography>
        <div className="container text-center">
          <div className="row justify-content-start">
            <div className="col-3">
              <TemplateBlock>
                <TemplateRendererWrapper>
                  <TemplateRenderer pass={inputs} />
                </TemplateRendererWrapper>
                <TemplateRendererWrapper>
                  <TemplateRenderer.Back pass={inputs} />
                </TemplateRendererWrapper>
              </TemplateBlock>
            </div>
            <div className="col-9">
              <InputsWrapper>
                <form onSubmit={handleSubmit}>
                  <div>
                    <FormControlLabel control={<Checkbox name='allow_anonymous' onChange={handleChecked} defaultChecked />} label={t('allowAnonymous')} />
                  </div>
                  { 'transit_type' in inputs && (
                    <>
                      <SelectLabel>
                        {t('boardingPass:transitType')}
                        <Select name={'transit_type'} value={inputs.transit_type} onChange={handleChange}>
                          <Option value="PKTransitTypeAir">{t('boardingPass:AIR')}</Option>
                          <Option value="PKTransitTypeBoat">{t('boardingPass:TRAIN')}</Option>
                          <Option value="PKTransitTypeBus">{t('boardingPass:BUS')}</Option>
                          <Option value="PKTransitTypeGeneric">{t('boardingPass:BOAT')}</Option>
                          <Option value="PKTransitTypeTrain">{t('boardingPass:GENERIC')}</Option>
                        </Select>
                      </SelectLabel>
                    </>
                  )}
                  <div>
                    <InputGroup column>
                      <Label>{t('verboseName')}</Label>
                      <Input required name='verbose_name' onChange={handleChange} />
                    </InputGroup>
                    <InputGroup column>
                      <Label>{t('description')}</Label>
                      <Input name='description' onChange={handleChange} />
                    </InputGroup>
                    <InputGroup column>
                      <Label>{t('logoText')}</Label>
                      <Input name='logo_text' onChange={handleChange} />
                    </InputGroup>
                    <InputGroup column>
                      <Label>{t('appLaunchUrl')}</Label>
                      <Input name='app_launch_url' onChange={handleChange} />
                    </InputGroup>
                    <InputGroup column>
                      <Label>{t('associatedStoreID')}</Label>
                      <Input name='associated_store_identifiers' onChange={handleChange} />
                    </InputGroup>
                    <InputGroup column>
                      <Label>{t('maxDistance')}</Label>
                      <Slider name='' min={100} max={999} valueLabelDisplay='auto' step={50} onChange={handleChange} />
                    </InputGroup>
                    <DesktopDateTimePicker label={t('expirationDate')} value={inputs.expiration_date} onChange={(e) => (setInputs(values => ({...values, expiration_date: e})))} renderInput={(params) => <TextField {...params} />} />
                    <DesktopDateTimePicker label={t('relevantDate')} value={inputs.relevant_date} onChange={(e) => (setInputs(values => ({...values, relevant_date: e})))} renderInput={(params) => <TextField {...params} />} />
                  </div>
                  <div>
                    <div style={{margin: "15px 0px"}}>
                      <Typography variant="h6">
                        {t('logoImage')}
                      </Typography>
                      <ImageInput name='logo_image' accept="image/png" onFileChange={handleFileFieldChange} />
                    </div>
                    <div style={{margin: "15px 0px"}}>
                      <Typography variant="h6">
                        {t('iconImage')}
                      </Typography>
                      <ImageInput name='icon_image' accept="image/png" onFileChange={handleFileFieldChange} />
                    </div>
                    { 'background_image' in inputs && (
                      <div style={{margin: "15px 0px"}}>
                        <Typography variant="h6">
                          {t('backgroundImage')}
                        </Typography>
                        <ImageInput name='background_image' accept="image/png" onFileChange={handleFileFieldChange} />
                      </div>
                    )}
                    { 'strip_image' in inputs && (
                      <div style={{margin: "15px 0px"}}>
                        <Typography variant="h6">
                          {t('stripImage')}
                        </Typography>
                        <ImageInput name='strip_image' accept="image/png" onFileChange={handleFileFieldChange} />
                      </div>
                    )}
                    { 'thumbnail_image' in inputs && (
                      <div style={{margin: "15px 0px"}}>
                        <Typography variant="h6">
                          {t('thumbnailImage')}
                        </Typography>
                        <ImageInput name='thumbnail_image' accept="image/png" onFileChange={handleFileFieldChange} />
                      </div>
                    )}
                    { 'footer_image' in inputs && (
                      <div style={{margin: "15px 0px"}}>
                        <Typography variant="h6">
                          {t('footerImage')}
                        </Typography>
                        <ImageInput name='footer_image' accept="image/png" onFileChange={handleFileFieldChange} />
                      </div>
                    )}
                  </div>
                  <div style={{margin: "15px 0px", display: "inline-flex", width: "100%"}}>
                    <div style={{margin: "0px 5px", flexBasis: "50%"}}>
                      <Typography variant="h6">
                        {t('foregroundColor')}
                      </Typography>
                      <CompactPicker color={inputs.foreground_color} onChange={(e) => (setInputs(v => ({...v, foreground_color: e.hex})))} />
                    </div>
                    <div style={{margin: "0px 5px", flexBasis: "50%"}}>
                      <Typography variant="h6">
                        {t('labelColor')}
                      </Typography>
                      <CompactPicker color={inputs.label_color} onChange={(e) => (setInputs(v => ({...v, label_color: e.hex})))} />
                    </div>
                  </div>
                  { !!!inputs.background_image && (
                    <div style={{margin: "5px 0px", flexBasis: "100%"}}>
                      <Typography variant="h6">
                        {t('backgroundColor')}
                      </Typography>
                      <CompactPicker color={inputs.background_color} onChange={(e) => (setInputs(v => ({...v, background_color: e.hex})))} />
                    </div>
                  )}
                  <hr/>
                  <div style={{display: "fiex", width: "100%"}}>
                    <Typography variant="h4" style={{margin: "15px 0px"}}>
                      {t('fields')}
                    </Typography>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                      { 'origin_field' in inputs && (
                        <div style={{display: "flex", flexBasis: "50%"}}>
                          <div style={{margin: "15px auto"}}>
                            <Typography variant="h6">
                              {t('originField')}
                            </Typography>
                            <FieldEdit field={inputs.origin_field} setField={setField('origin_field')} companyId={realm?.id} />  
                          </div>
                        </div>
                      )}
                      { 'destination_field' in inputs && (
                        <div style={{display: "flex", flexBasis: "50%"}}>
                          <div style={{margin: "15px auto"}}>
                            <Typography variant="h6">
                              {t('destinationField')}
                            </Typography>
                            <FieldEdit field={inputs.destination_field} setField={setField('destination_field')} companyId={realm?.id} />  
                          </div>
                        </div>
                      )}
                      { 'primary_field' in inputs && (
                        <div style={{display: "flex", flexBasis: "100%"}}>
                          <div style={{margin: "15px auto"}}>
                            <Typography variant="h6">
                              {t('primaryField')}
                            </Typography>
                            <FieldEdit field={inputs.primary_field} setField={setField('primary_field')} companyId={realm?.id} />  
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <hr/>
                  <div style={{display: "inline-flex"}}>
                    <div style={{margin: "15px"}}>
                      <Typography variant="h6">
                        {t('headerFields')}
                      </Typography>
                      <FieldListEdit fields={inputs.header_fields} setFields={setFields('header_fields')} max={3} companyId={realm?.id} />
                    </div>
                    <div style={{margin: "15px"}}>
                      <Typography variant="h6">
                        {t('secondaryFields')}
                      </Typography>
                      <FieldListEdit fields={inputs.secondary_fields} setFields={setFields('secondary_fields')} max={4} companyId={realm?.id} />
                    </div>
                    <div style={{margin: "15px"}}>
                      <Typography variant="h6">
                        {t('auxiliaryFields')}
                      </Typography>
                      <FieldListEdit fields={inputs.auxiliary_fields} setFields={setFields('auxiliary_fields')} max={4} companyId={realm?.id} />
                    </div>
                    <div style={{margin: "15px"}}>
                      <Typography variant="h6">
                        {t('backFields')}
                      </Typography>
                      <FieldListEdit fields={inputs.back_fields} setFields={setFields('back_fields')} companyId={realm?.id} />
                    </div>
                  </div>
                  <hr/>
                  <div style={{display: "inline-flex"}}>
                    <div style={{margin: "15px"}}>
                      <Typography variant="h6">
                        {t('ibeaconList')}
                      </Typography>
                      <IBeaconListEdit ibeacons={inputs.ibeacons} setIBeacons={setFields('ibeacons')} />
                    </div>
                    <div style={{margin: "15px"}}>
                      <Typography variant="h6">
                        {t('locationList')}
                      </Typography>
                      <LocationListEdit locations={inputs.locations} setLocations={setFields('locations')} />
                    </div>
                    <div style={{margin: "15px"}}>
                      <Typography variant="h6">
                        {t('barcodeList')}
                      </Typography>
                      <BarcodeListEdit barcodes={inputs.barcodes} setBarcodes={setFields('barcodes')} />
                    </div>
                  </div>
                  <hr/>
                  <Button>{t('generatePass')}</Button>
                </form>
              </InputsWrapper>
            </div>
          </div>
        </div>
      </LocalizationProvider>
    );
  }
  return CreateTemplate;
}