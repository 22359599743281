import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from 'i18next';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: true,
        detection: {
            order: ['queryString', 'cookie'],
            cache: ['cookie']
        },
        interpolation: {
            escapeValue: false
        },
        ns: [],
        resources: {},
        defaultNS: 'common',
        lng: 'ru',
        fallbackLng: 'en',
    })

export default i18n;