import { createPassComponent } from "components/reusable/pass";
import { GenericPass } from "components/passes/genericPass";
import { useGenerateGenericPassMutation, useRetrieveGenericPassTemplateQuery } from "services/genericPasses";
import { GenericPassInputs } from "types";


export const AdminCreateGenericPass = createPassComponent<GenericPassInputs>({
  isAdminView: true,
  TemplateRenderer: GenericPass,
  successUrl: '/backoffice/generic-passes/',
  i18nNamespace: 'genericPass',
  createPassHook: useGenerateGenericPassMutation,
  retrievePassTemplateHook: useRetrieveGenericPassTemplateQuery,
})

export const CreateGenericPass = createPassComponent<GenericPassInputs>({
  isAdminView: false,
  TemplateRenderer: GenericPass,
  successUrl: '/',
  i18nNamespace: 'genericPass',
  createPassHook: useGenerateGenericPassMutation,
  retrievePassTemplateHook: useRetrieveGenericPassTemplateQuery,
})