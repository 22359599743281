import dayjs from 'dayjs';
import { useCreateBoardingPassMutation } from 'services/boardingPasses';
import { BoardingPass } from '../boardingPass';
import { BoardingPassInputs, Field } from 'types';
import { createTemplateComponent } from 'components/reusable/template';


const initialData = {
  owner: undefined,
  allow_anonymous: false,
  transit_type: "PKTransitTypeAir",
  verbose_name: '',
  description: '',
  app_launch_url: null,
  associated_store_identifiers: null,
  max_distance: 999,
  background_color: "#ffffff",
  foreground_color: "#000000",
  label_color: "#000000",
  expiration_date: dayjs(),
  relevant_date: dayjs(),
  logo_text: '',
  header_fields: [],
  back_fields: [],
  secondary_fields: [],
  auxiliary_fields: [],
  ibeacons: [],
  locations: [],
  barcodes: [],
  origin_field: null,
  destination_field: null,
  logo_image: undefined,
  icon_image: undefined,
  footer_image: undefined,
}

export const CreateBoardingPassTemplate = createTemplateComponent<BoardingPassInputs>({
  initialData: initialData,
  TemplateRenderer: BoardingPass,
  successBaseUrl: '/boarding-passes',
  i18nNamespace: 'boardingPass',
  createTemplateHook: useCreateBoardingPassMutation,
});