import {
  Pass,
  PassAuxiliaryFields,
  PassBack,
  PassBarcode,
  PassBody,
  PassBodyBottom,
  PassBodyTop,
  PassBodyTopStripImage,
  PassBodyTopStripImageContainer,
  PassContainer,
  PassHeader,
  PassHeaderField,
  PassHeaderLabel,
  PassHeaderValue,
  PassLogo,
  PassLogoContainer,
  PassLogoText,
  PassPrimaryField,
  PassPrimaryLabel,
  PassPrimaryValue,
  PassSecondaryField,
  PassSecondaryFields,
  PassSecondaryLabel,
  PassSecondaryValue,
  PassHorizontalRule,
  PassFooter,
} from 'components/passes/pass';
import { StoreCard as StoreCardType, StoreCardInputs, CreatedPass } from 'types';
import { getFullPath } from 'utils/url';


export function StoreCard({pass, createdPass} : { pass: StoreCardType | StoreCardInputs | undefined, createdPass?: CreatedPass | undefined | null}) {

  const logoImage = createdPass?.user_info?.image_values?.logo_image ? getFullPath(createdPass?.user_info?.image_values?.logo_image) : pass?.logo_image;
  const stripImage = createdPass?.user_info?.image_values?.strip_image ? getFullPath(createdPass?.user_info?.image_values?.strip_image) : pass?.strip_image;
  
  const getFieldOverrideValues = (field: any) => {
    const field_values = createdPass?.user_info?.field_values;
    if (field_values) {
      if (Object.keys(field_values).includes(field.key) && field_values[field.key].value) {
        return field_values[field.key].value;
      }
    }
    return field.value;
  }

  const getFieldOverrideLabels = (field: any) => {
    const field_values = createdPass?.user_info?.field_values;
    if (field_values) {
      if (Object.keys(field_values).includes(field.key) && field_values[field.key].label) {
        return field_values[field.key].label;
      }
    }
    return field.label;
  }
  
  return (
    <PassContainer>
      <Pass backgroundColor={pass?.background_color} foregroundColor={pass?.foreground_color}>
        <PassHeader>
          <PassLogoContainer>
            <PassLogo src={logoImage} />
          </PassLogoContainer>
          <PassLogoText>
            {pass?.logo_text}
          </PassLogoText>
          { pass?.header_fields?.map((field: any) => {
              return(
              <PassHeaderField key={field.key}>
                <PassHeaderLabel color={pass?.label_color}>
                  {getFieldOverrideLabels(field)}
                </PassHeaderLabel>
                <PassHeaderValue>
                  {getFieldOverrideValues(field)}
                </PassHeaderValue>
              </PassHeaderField>
              )
            })
          }
        </PassHeader>
        <PassHorizontalRule labelColor={pass?.label_color} />
        <PassBody>
          { (pass?.primary_field || pass?.strip_image) && (
            <PassBodyTop>
              { pass?.strip_image && (
                <PassBodyTopStripImageContainer>
                  <PassBodyTopStripImage src={stripImage} />
                </PassBodyTopStripImageContainer>
              )}
              { pass?.primary_field && (
                <PassPrimaryField>
                  <PassPrimaryLabel color={pass?.label_color}>
                    {getFieldOverrideLabels(pass?.primary_field)}
                  </PassPrimaryLabel>
                  <PassPrimaryValue>
                    {getFieldOverrideValues(pass?.primary_field)}
                  </PassPrimaryValue>
                </PassPrimaryField>
              )}
            </PassBodyTop>
          )}
          <PassBodyBottom>
            <PassSecondaryFields>
            { pass?.secondary_fields?.map((field: any) => {
              return(
                <PassSecondaryField key={field.key}>
                  <PassSecondaryLabel color={pass?.label_color}>
                    {getFieldOverrideLabels(field)}
                  </PassSecondaryLabel>
                  <PassSecondaryValue>
                    {getFieldOverrideValues(field)}
                  </PassSecondaryValue>
                </PassSecondaryField>
              )
            }) }
            </PassSecondaryFields>
            <PassAuxiliaryFields >
            </PassAuxiliaryFields>
          </PassBodyBottom>
        </PassBody>
        <PassFooter>
          { !!pass?.barcodes?.length && pass?.barcodes?.length > 0 && (
            <PassBarcode barcodes={pass?.barcodes} createdPass={createdPass}  />
          )}
        </PassFooter>
      </Pass>
    </PassContainer>
  )
}


StoreCard.Back = PassBack<StoreCardInputs>;