import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useAppSelector } from 'app/hooks';
import { useGetUserDataQuery } from 'services/users';



const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 16px;
  position: sticky;
  top: 0;
  z-index: 1;
  border-bottom: 1px solid #ddd;

  .logo {
    display: flex;
    align-items: center;

    img {
      height: 40px;
      margin-right: 8px;
    }

    h1 {
      font-size: 20px;
      color: #333;
      margin: 0;
    }
  }

  nav ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: inline-block;
      margin-right: 16px;

      a {
        color: #333;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
`;

const StyledLink = styled.a`
  color: #333;
`

const Main = styled.main`
  padding: 40px;

  section {
    margin-bottom: 40px;
    margin-top: 140px;

    &:first-child {
      margin-top: 0;
    }

    h2 {
      font-size: 28px;
      margin-bottom: 10px;
    }

    p {
      margin-bottom: 8px;
    }

    ul {
      list-style: disc;
      padding-left: 20px;
    }

    a {
      color: #333;
    }
  }
`;


const SectionContainer = styled.section<{ align?: 'left' | 'center' }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ align }) => (align === 'left' ? 'flex-start' : 'center')};
  margin-bottom: 40px;

  .image {
    width: 100%;
    max-width: 400px;
    margin-bottom: 24px;
  }

  h3 {
    font-size: 24px;
    margin-bottom: 16px;
    text-align: ${({ align }) => align};
  }

  p {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 16px;
    max-width: 600px;
    text-align: 'justify';
    
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 24px;
  max-width: 900px;

  img {
    width: 400px;
    max-width: 900px;
    margin: 0 20px 20px 0;
  }

`;

const TextParagraph = styled.p`
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 16px;
  max-width: 800px;
  text-align: justify;
`;

const ImageWrapper = styled.div<{ imageAlign?: 'left' | 'right' }>`
  display: flex;
  justify-content: ${({ imageAlign }) => (imageAlign === 'left' ? 'flex-start' : 'flex-end')};
  margin: 30px 20px;

  img {
    max-width: 800px;
  }
`;


const HeroSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
  text-align: center;

  opacity: 0;
  animation: fadeIn 2s forwards;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  h1 {
    font-size: 32px;
    margin-bottom: 16px;
  }

  p {
    font-size: 18px;
    margin-bottom: 24px;
  }

  button {
    padding: 12px 24px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    background-color: #333;
    color: #fff;
    cursor: pointer;

    &:hover {
      background-color: #111111;
    }
  }
`;

const Footer = styled.footer`
  background-color: #f5f5f5;
  padding: 16px;
  text-align: center;
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 16px;
`;

const Title = styled.h3`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 8px;
`;

function LandingPage() {
  const { t } = useTranslation();

  useGetUserDataQuery();

  useEffect(() => {
    document.title = 'SimpleWallet';
  }, []);

  const user = useAppSelector((state) => state.users?.user);

  return (
    <div>
      <Header>
        <div className="logo">
          <img src="/logo.png" alt="Logo" />
          <h1></h1>
        </div>
        <nav>
          <ul>
            <li>
              <a href="#about">About</a>
            </li>
            <li>
              <a href="#features">Features</a>
            </li>
            <li>
              <a href="#cases">Cases</a>
            </li>
            <li>
              <a href="#contact">Contact</a>
            </li>
            <li>
              {user?.id ? (
                <StyledLink href="/backoffice/">
                  {t('Backoffice')}
                </StyledLink>
              ) : (
                <StyledLink href="/sign-in/">
                  {t('Sign in')}
                </StyledLink>
              )}
            </li>
          </ul>
        </nav>
      </Header>
      <Main>
        <HeroSection>
          <h1>Enjoy the usage of SimpleWallet</h1>
          <p>The best way to serve your digital tickets, passes & cards</p>
          <Link className="dropdown-item" to='/sign-up/'>
            <button>Get Started</button>
          </Link>
        </HeroSection>

        <section id="about">
          <SectionContainer>
            <h2>What is SimpleWallet?</h2>
            <ImageWrapper>
              <img src="/images/wallet-passes.png" alt="Wallet Passes" />
            </ImageWrapper>
            <p>SimpleWallet is an application to administrate your Apple Wallet Passes - digital representations of cards, tickets, coupons, and more that can be stored in the Apple Wallet app on iOS devices. They offer easy access, customizable design, dynamic updates, location-based triggers, and notifications. Passes are secure, offline accessible, and can be integrated with other apps. They provide a convenient and eco-friendly way to store and manage various passes digitally.</p>
          </SectionContainer>
        </section>

        <section id="features">
          <SectionContainer>
            <h2>Features?</h2>
            <p>Sure, a plenty of them!</p>
            <ImageWrapper>
              <img src="/images/wallet-different-passes.png" alt="Boarding Pass" />
            </ImageWrapper>
            <h3>Easy Access</h3><TextParagraph>Apple Wallet Passes are stored directly on the user's iPhone, iPad, or Apple Watch, allowing quick and easy access to passes when needed. Passes can be displayed with just a tap, even from the lock screen.</TextParagraph>
            <h3>Pass Types</h3><TextParagraph>Apple Wallet supports various pass types, including boarding passes, event tickets, store cards, loyalty cards, coupons, student IDs, transit passes, and more. Each pass type can have specific features and functionalities tailored to its purpose.</TextParagraph>
            <h3>Customizable Design</h3><TextParagraph>Passes can be designed with custom branding, colors, logos, and relevant information. This enables businesses and organizations to create visually appealing passes that align with their brand identity.</TextParagraph>
            <h3>Dynamic Updates</h3><TextParagraph>Passes can be dynamically updated with new information even after they have been added to Apple Wallet. This allows real-time updates for things like flight changes, event details, or updated loyalty point balances.</TextParagraph>
            <h3>Location and Time-based Triggers</h3><TextParagraph>Passes can be set to appear automatically based on specific triggers like location or time. For example, a store card pass can appear on the lock screen when the user is near the store, or an event ticket can appear right before the event starts.</TextParagraph>
            <ImageContainer>
              <ImageWrapper>
                <img src="/images/1.png" alt="Boarding Pass" />
                <img src="/images/3.jpg" alt="Boarding Pass" />
              </ImageWrapper>
            </ImageContainer>
            <h3>Notifications</h3><TextParagraph>Passes can send notifications to the lock screen, notifying users about relevant updates, time-sensitive information, or reminders. This helps users stay informed and organized.</TextParagraph>
            <h3>Secure and Private</h3><TextParagraph>Apple Wallet Passes leverage Apple's security measures, including encryption and device-specific authentication, ensuring that the passes and the user's personal information remain secure.</TextParagraph>
            <h3>Offline Availability</h3><TextParagraph>Passes are available for use even when the device is offline, making them reliable in situations where internet connectivity is limited.</TextParagraph>
            <h3>Integration with Apps and Services</h3><TextParagraph>Developers can integrate Apple Wallet Passes with their own apps and services, providing a seamless user experience and additional functionalities.</TextParagraph>
            <h3>Environmentally Friendly</h3><TextParagraph>By eliminating the need for physical cards and paper tickets, Apple Wallet Passes contribute to reducing waste and promoting eco-friendly practices.</TextParagraph>
          </SectionContainer>
        </section>

        <section id="cases">
          <SectionContainer>
            <h2>Cases?</h2>
            <p>Yes. Successful? Absolutely!</p>
            <h3>Flight school "Wings"</h3><TextParagraph>It's our first & popular template among visitors of Flight School Wings. Initially made for the school's cadets, the template has evolved into a visitor card, a digital flight certificate, and a customer communication channel capable of automatically reporting information based on the holder's time and location.</TextParagraph>
            <ImageWrapper>
              <img src="/images/Graphite.png" alt="Boarding Pass" width={400}/>
            </ImageWrapper>
          </SectionContainer>
        </section>

        <section id="contact">
          <SectionContainer>
            <h2>Contact Us</h2>
            <p>Get in touch with us for any inquiries or feedback via <a href = "mailto: eugene@spazier.io">email</a></p>
          </SectionContainer>
        </section>
      </Main>

      <Footer>
        <p>&copy; 2024 SimpleWallet. All rights reserved.</p>
      </Footer>
    </div>
  );
}

export default LandingPage;