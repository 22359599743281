import {
    Pass,
    PassAuxiliaryField,
    PassAuxiliaryFields,
    PassAuxiliaryLabel,
    PassAuxiliaryValue,
    PassBack,
    PassBarcode,
    PassBody,
    PassBodyBottom,
    PassBodyTop,
    PassBodyTopFieldContainer,
    PassContainer,
    PassHeader,
    PassHeaderField,
    PassHeaderLabel,
    PassHeaderValue,
    PassLogo,
    PassLogoContainer,
    PassLogoText,
    PassPrimaryField,
    PassPrimaryLabel,
    PassPrimaryValue,
    PassSecondaryField,
    PassSecondaryFields,
    PassSecondaryLabel,
    PassSecondaryValue,
    PassThumbnail,
    PassThumbnailContainer,
    PassHorizontalRule,
    PassFooter,
  } from 'components/passes/pass';
import { GenericPass as GenericPassType, GenericPassInputs, CreatedPass } from 'types';
  

export const GenericPass = ({ pass, createdPass } : { pass: GenericPassType | GenericPassInputs | undefined, createdPass?: CreatedPass | undefined | null }) => {
  const logoImage = createdPass?.user_info?.image_values?.logo_image ? createdPass?.user_info?.image_values?.logo_image : pass?.logo_image;
  const thumbnailImage = createdPass?.user_info?.image_values?.thumbnail_image ? createdPass?.user_info?.image_values?.thumbnail_image : pass?.thumbnail_image;

  return (
    <PassContainer>
      <Pass backgroundColor={pass?.background_color} foregroundColor={pass?.foreground_color}>
        <PassHeader>
          <PassLogoContainer>
            <PassLogo src={logoImage} />
          </PassLogoContainer>
          <PassLogoText>
            {pass?.logo_text}
          </PassLogoText>
          { pass?.header_fields?.map((field: any) => {
              return(
              <PassHeaderField key={field.key}>
                <PassHeaderLabel color={pass?.label_color}>
                  {field.label}
                </PassHeaderLabel>
                <PassHeaderValue>
                  {field.value}
                </PassHeaderValue>
              </PassHeaderField>
              )
            })
          }
        </PassHeader>
        <PassHorizontalRule labelColor={pass?.label_color} />        
        <PassBody>
          { (pass?.primary_field || pass?.thumbnail_image) && (
            <PassBodyTop>
              { pass?.primary_field && (
                <PassBodyTopFieldContainer>
                  <PassPrimaryField>
                    <PassPrimaryLabel color={pass?.label_color}>
                      {pass?.primary_field?.label}
                    </PassPrimaryLabel>
                    <PassPrimaryValue>
                      {pass?.primary_field?.value}
                    </PassPrimaryValue>
                  </PassPrimaryField>
                </PassBodyTopFieldContainer>
              )}
              { pass?.thumbnail_image && (
                <PassThumbnailContainer>
                  <PassThumbnail src={thumbnailImage} />
                </PassThumbnailContainer>
              )}
            </PassBodyTop>
          )}
          <PassBodyBottom>
            <PassSecondaryFields>
            { pass?.secondary_fields?.map((field: any) => {
              return(
                <PassSecondaryField key={field.key}>
                  <PassSecondaryLabel color={pass?.label_color}>
                    {field.label}
                  </PassSecondaryLabel>
                  <PassSecondaryValue>
                    {field.value}
                  </PassSecondaryValue>
                </PassSecondaryField>
              )
            })}
            </PassSecondaryFields>
            <PassAuxiliaryFields >
            { pass?.auxiliary_fields?.map((field: any) => {
              return(
                <PassAuxiliaryField key={field.key}>
                  <PassAuxiliaryLabel color={pass?.label_color}>
                    {field.label}
                  </PassAuxiliaryLabel>
                  <PassAuxiliaryValue>
                    {field.value}
                  </PassAuxiliaryValue>
                </PassAuxiliaryField>
              )
            })}
            </PassAuxiliaryFields>
          </PassBodyBottom>

        </PassBody>
        <PassFooter>
          { !!pass?.barcodes?.length && pass?.barcodes?.length > 0 && (
            <PassBarcode barcodes={pass?.barcodes} createdPass={createdPass}  />
          )}
        </PassFooter>
      </Pass>
    </PassContainer>
  )
}


GenericPass.Back = PassBack<GenericPassInputs>;