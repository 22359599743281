import { useRetrieveCouponQuery, useDeleteCouponPassMutation, useDeleteCouponMutation } from 'services/coupons';
import { Coupon } from '../coupon';
import { createTemplateDetails } from 'components/reusable/template';


export const CouponDetails = createTemplateDetails({
  TemplateRenderer: Coupon,
  retrieveHook: useRetrieveCouponQuery,
  deletePassHook: useDeleteCouponPassMutation,
  deleteTemplateHook: useDeleteCouponMutation,
  baseUrl: "/coupons/",
  i18nNamespace: "coupon",
})