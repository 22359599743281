import { createPassComponent } from "components/reusable/pass";
import { EventTicket } from "components/passes/eventTicket";
import { useGenerateEventTicketMutation, useRetrieveEventTicketTemplateQuery } from "services/eventTickets";


export const AdminCreateEventTicket = createPassComponent({
  isAdminView: true,
  TemplateRenderer: EventTicket,
  successUrl: '/backoffice/event-tickets/',
  i18nNamespace: 'eventTicket',
  createPassHook: useGenerateEventTicketMutation,
  retrievePassTemplateHook: useRetrieveEventTicketTemplateQuery,
})

export const CreateEventTicket = createPassComponent({
  isAdminView: false,
  TemplateRenderer: EventTicket,
  successUrl: '/',
  i18nNamespace: 'eventTicket',
  createPassHook: useGenerateEventTicketMutation,
  retrievePassTemplateHook: useRetrieveEventTicketTemplateQuery,
})