import {
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import LoginPage from "./auth/sign-in";
import { 
  BoardingPassDashboard,
  BoardingPassDetails,
  GenerateBoardingPass,
  AdminGenerateBoardingPass,
  UpdateBoardingPass,
  CreateBoardingPassTemplate,
  EditBoardingPassTemplate, } from 'components/passes/boardingPass';
import { 
  CouponDashboard,
  CouponDetails,
  AdminCreateCoupon,
  CreateCoupon,
  UpdateCouponPass,
  CreateCouponTemplate,
  EditCouponTemplate,
} from 'components/passes/coupon';
import { 
  StoreCardDashboard,
  StoreCardDetails,
  AdminCreateStoreCard,
  CreateStoreCard,
  UpdateStoreCard,
  CreateStoreCardTemplate,
  EditStoreCardTemplate, } from 'components/passes/storeCard';
import {
  CreateGenericPassTemplate,
  GenericPassTemplateDashboard,
  EditGenericPassTemplate,
  GenericPassTemplateDetails,
  AdminCreateGenericPass,
  CreateGenericPass,
} from 'components/passes/genericPass';
import {
  EventTicketDashboard, EventTicketTemplateDetails, CreateEventTicketTemplate,
  EditEventTicketTemplate, CreateEventTicket, AdminCreateEventTicket, UpdateEventTicket,
} from 'components/passes/eventTicket';
import { ScenarioPage } from "components/scenarios";
import { BackofficeContainer } from "./page";
import LandingPage from "./main";
import { SendPush } from "./passes/boardingPass/sendPush";


export default function AppRouter() {
  return (
    <Routes>
      <Route path="sign-in" element={<LoginPage />} />
      {/* <Route path="sign-up-company" element={<RegisterCompanyPage />} />
      <Route path="sign-up-user" element={<RegisterUserPage />} />
      <Route path="dashboard" element={<Dashboard />} /> */}
      <Route path="backoffice/" element={<BackofficeContainer children={<Outlet />}/>} >
        <Route path="boarding-passes/" element={<Outlet />} >
          <Route path="create/" element={<CreateBoardingPassTemplate />} />
          <Route path=":id/" element={<BoardingPassDetails />} />
          <Route path=":id/edit/" element={<EditBoardingPassTemplate />} />
          <Route path=":id/create/" element={<AdminGenerateBoardingPass />} />
          <Route path=":id/send-push/:passId/" element={<SendPush />} />
          <Route path=":id/update/:passId/" element={<UpdateBoardingPass />} />
          <Route index element={<BoardingPassDashboard />} />
        </Route>
        <Route path="coupons/" element={<Outlet />} >
          <Route path="create/" element={<CreateCouponTemplate />} />
          <Route path=":id/" element={<CouponDetails />} />
          <Route path=":id/edit/" element={<EditCouponTemplate />} />
          <Route path=":id/create/" element={<AdminCreateCoupon />} />
          {/* <Route path=":id/send-push/:passId/" element={<SendPush />} /> */}
          <Route path=":id/update/:passId/" element={<UpdateCouponPass />} />
          <Route index element={<CouponDashboard />} />
        </Route>
        <Route path="store-cards/" element={<Outlet />} >
          <Route path="create/" element={<CreateStoreCardTemplate />} />
          <Route path=":id/" element={<StoreCardDetails />} />
          <Route path=":id/edit/" element={<EditStoreCardTemplate />} />
          <Route path=":id/create/" element={<AdminCreateStoreCard />} />
          <Route path=":id/update/:passId/" element={<UpdateStoreCard />} />
          <Route index element={<StoreCardDashboard />} />
        </Route>
        <Route path="event-tickets/" element={<Outlet />} >
          <Route path="create/" element={<CreateEventTicketTemplate />} />
          <Route path=":id/" element={<EventTicketTemplateDetails />} />
          <Route path=":id/edit/" element={<EditEventTicketTemplate />} />
          <Route path=":id/create/" element={<AdminCreateEventTicket />} />
          <Route path=":id/update/:passId/" element={<UpdateEventTicket />} />
          <Route index element={<EventTicketDashboard />} />
        </Route>
        <Route path="generic-passes/" element={<Outlet />} >
          <Route path="create/" element={<CreateGenericPassTemplate />} />
          <Route path=":id/" element={<GenericPassTemplateDetails />} />
          <Route path=":id/edit/" element={<EditGenericPassTemplate />} />
          <Route path=":id/create/" element={<AdminCreateGenericPass />} />
          {/* <Route path=":id/update/:passId/" element={<UpdateStoreCardPass />} /> */}
          <Route index element={<GenericPassTemplateDashboard />} />
        </Route>
        <Route path=":passType/:passId/scenarios/:scenarioType/:scenarioId/" element={<ScenarioPage />} />

      </Route>
      <Route path="boarding-passes/:id/create/" element={<GenerateBoardingPass />} />
      <Route path="event-tickets/:id/create/" element={<CreateEventTicket />} />
      <Route path="generic-passes/:id/create/" element={<CreateGenericPass />} />
      <Route path="coupons/:id/create/" element={<CreateCoupon />} />
      <Route path="store-cards/:id/create/" element={<CreateStoreCard />} />

      {/* <Route path="coupons" element={<BoardingPassDashboard />} />
      <Route path="event-tickets" element={<BoardingPassDashboard />} />
      <Route path="generic-passes" element={<BoardingPassDashboard />} />
      <Route path="store-cards" element={<BoardingPassDashboard />} /> */}
      <Route path="profile/" element={<div>Profile</div>} />
      <Route path="home/" element={<div>Home</div>} />
      <Route path="features/" element={<div>Features</div>} />
      <Route path="pricing/" element={<div>Pricing</div>} />
      <Route path="about/" element={<div>About</div>} />
      <Route path="contacts/" element={<div>Contacts</div>} />
      <Route path="/" element={<LandingPage />} />
    </Routes>
  )
}
