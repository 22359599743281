import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  SmallTransparentButton,
  SmallLabel,
  SmallOption,
  SmallSelect,
  TooltipButton,
  TooltipText,
  BorderedButtonGroup
} from 'components/reusable/buttons';
import { Table, TableCell, TableHead, TableRow } from 'components/reusable/tables';
import { BASE_URL } from 'constants/api';


export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;

  button:nth-child(even) {
    margin: 0 5px;
  }
`;


export default function CreatedPassTable({ data, deletePass }) {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getDownloadLink = (passId) => BASE_URL + passId.data;


  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('pass:id')}</TableCell>
            <TableCell>{t('pass:serialNumber')}</TableCell>
            <TableCell>{t('pass:updatedAt')}</TableCell>
            <TableCell>{t('pass:actions')}</TableCell>
          </TableRow>
        </TableHead>
        <tbody>
          { data.length === 0 && (
            <TableRow>
              <TableCell colSpan={4}>{t('pass:noCreatedPasses')}</TableCell>
            </TableRow>
          )}
          {(rowsPerPage > 0
            ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : data
          ).map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.id}</TableCell>
              <TableCell>{row.serial_number}</TableCell>
              <TableCell>{new Date(row.updated_at).toLocaleString()}</TableCell>
              <TableCell>
                <BorderedButtonGroup>
                  <a href={getDownloadLink(row)} download>
                    <SmallTransparentButton>{t('pass:download')}</SmallTransparentButton>
                    {/* <TooltipButton>
                      <img src="/icons/download.svg" alt="download"/>
                      <TooltipText>{t('pass:download')}</TooltipText>
                    </TooltipButton> */}
                  </a>
                  <Link to={'update/' + row.id + '/'}>
                      <SmallTransparentButton>{t('pass:update')}</SmallTransparentButton>
                      {/* <TooltipButton>
                        <img src="/icons/update.svg" alt="update"/>
                        <TooltipText>{t('pass:update')}</TooltipText>
                      </TooltipButton> */}
                  </Link>
                  <Link to={'send-push/' + row.id + '/'}>
                      <SmallTransparentButton>{t('pass:sendPush')}</SmallTransparentButton>
                      {/* <TooltipButton>
                        <img src="/icons/send-message.svg" alt="send"/>
                        <TooltipText>{t('pass:sendPush')}</TooltipText>
                      </TooltipButton> */}
                  </Link>
                  <SmallTransparentButton onClick={() => deletePass(row.id)}>{t('pass:delete')}</SmallTransparentButton>
                  {/* <TooltipButton onClick={() => deletePass(row.id)}>
                    <img src="/icons/delete.svg" alt="delete"/>
                    <TooltipText>{t('pass:delete')}</TooltipText>
                  </TooltipButton> */}
                </BorderedButtonGroup>
              </TableCell>
            </TableRow>
          ))}
          {data.length > 0 && data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length < rowsPerPage && (
            <TableRow style={{ height: 42 * (rowsPerPage - data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length) }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </tbody>
      </Table>
      <PaginationContainer>
        <SmallLabel>
          {t('pass:rowsPerPage')}
          <SmallSelect value={rowsPerPage} onChange={handleChangeRowsPerPage}>
            <SmallOption value={5}>5</SmallOption>
            <SmallOption value={10}>10</SmallOption>
            <SmallOption value={25}>25</SmallOption>
            <SmallOption value={-1}>All</SmallOption>
          </SmallSelect>
        </SmallLabel>
        <SmallTransparentButton onClick={() => handleChangePage(page - 1)} disabled={page === 0}>{t('pass:prevPage')}</SmallTransparentButton>
        <SmallTransparentButton onClick={() => handleChangePage(page + 1)} disabled={page >= Math.ceil(data.length / rowsPerPage) - 1}>{t('pass:nextPage')}</SmallTransparentButton>
      </PaginationContainer>
    </div>
  );
}