import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/reusable/buttons';
import styled from 'styled-components';
import { ContainerWrapper } from 'components/reusable/containers';
import CreatedPassTable from 'components/reusable/passTable';
import { TemplateBlock, TemplateRendererWrapper } from 'components/reusable/containers';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;

  button {
    margin: 10px;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Tinted background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it appears above other content */
`;

const ModalContent = styled.div`
  background: white; /* Solid background */
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001; /* Ensure it appears above the overlay */
`;

const ModalButton = styled(Button)`
  margin: 10px;
`;

interface TemplateDetailsProps {
  TemplateRenderer: any;
  retrieveHook: (id: string) => any;
  deletePassHook: () => any;
  deleteTemplateHook: () => any;
  baseUrl: string;
  i18nNamespace: string;
};


export const createTemplateDetails = ({TemplateRenderer, retrieveHook, deletePassHook, deleteTemplateHook, baseUrl, i18nNamespace}: TemplateDetailsProps) => {
  const TemplateDetails = () => {

    const { t } = useTranslation(i18nNamespace);
    const { id } = useParams();
    const navigate = useNavigate();
    const { data, isLoading, isSuccess: isRetrieveSuccess } = retrieveHook(String(id));
    const [ deleteCreatedPass, { isSuccess: isDeleteSuccess } ] = deletePassHook();
    const [ deleteTemplate, { isSuccess: isDeleteTemplateSuccess } ] = deleteTemplateHook();
    const [scenarioType, setScenarioType] = useState<string | null>(null);
    const [scenarioId, setScenarioId] = useState<string | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false); // Add state for modal

    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);
    const handleConfirmDelete = () => {
      onDeleteTemplate();
      handleCloseModal();
    };

    const deletePass = (passId: string) => {
      deleteCreatedPass({templateId: String(id), passId})
    }

    useEffect(() => {
      if(isDeleteSuccess) {
        window.location.reload();
      }
      if(isRetrieveSuccess) {
        if (Object.keys(data).includes('scenarios') && Object.keys(data.scenarios).length > 0) {
          for (const [key, value] of Object.entries(data.scenarios)) {
            setScenarioType(key);
            setScenarioId(value as string);
          }
        }
      }
    }, [isDeleteSuccess, isRetrieveSuccess])

    const openScenarioDashboard = () => {
      navigate(`scenarios/${scenarioType}/${scenarioId}/`, { state: { passTemplate: data } });
    }

    const onDeleteTemplate = () => {
      deleteTemplate(String(id))
    }

    useEffect(() => {
      if(isDeleteTemplateSuccess) {
        navigate('/backoffice' + baseUrl)
      }
    }, [isDeleteTemplateSuccess])

    if(isLoading) {
      return(<div>{t('loading')}</div>)
    } else {
      return (
        <ContainerWrapper>
          <Grid container>
            <div className="container text-center">
              <div className="row justify-content-start">
                <div className="col-3">
                  <TemplateBlock>
                    <TemplateRendererWrapper>
                      <TemplateRenderer pass={data} />
                    </TemplateRendererWrapper>
                    <TemplateRendererWrapper>
                      <TemplateRenderer.Back pass={data} />
                    </TemplateRendererWrapper>
                    <Link to='edit/'>
                      <Button>{t('edit')}</Button>
                    </Link>
                    <Button color='#cc0000' onClick={handleOpenModal}>{t('delete')}</Button> {/* Update button */}
                  </TemplateBlock>
                </div>
                <div className="col-9">
                  <ButtonWrapper>
                    <Link to='create/'>
                      <Button>{t('generateNewAdmin')}</Button>
                    </Link>
                    <Link to={baseUrl + id + '/create/'}>
                      <Button>{t('generateNewClient')}</Button>
                    </Link>
                    <Button onClick={openScenarioDashboard} hidden={!scenarioType}>{t('scenarioDashboard')}</Button>
                  </ButtonWrapper>
                  <CreatedPassTable data={data?.created_passes} deletePass={deletePass}></CreatedPassTable>
                </div>
              </div>
            </div>
          </Grid>
          {isModalOpen && (
            <ModalOverlay>
              <ModalContent>
                <h2>{t('confirmDelete')}</h2>
                <p>{t('areYouSure')}</p>
                <ModalButton onClick={handleConfirmDelete}>{t('yes')}</ModalButton>
                <ModalButton onClick={handleCloseModal}>{t('no')}</ModalButton>
              </ModalContent>
            </ModalOverlay>
          )}
        </ContainerWrapper>
      );
    }
  }
  return TemplateDetails;
};