import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import boardingPassReducer from '../features/boardingPass/boardingPassSlice';
import couponReducer from '../features/coupons/couponSlice';
import storeCardReducer from '../features/storeCards/storeCardSlice';
import eventTicketReducer from '../features/eventTickets/eventTicketSlice';
import genericPassReducer from '../features/genericPasses/genericPassSlice';
import usersReducer from '../features/users/usersSlice';
import { api } from '../services/api';

export const store = configureStore({
  reducer: {
    boardingPasses: boardingPassReducer,
    coupons: couponReducer,
    storeCards: storeCardReducer,
    eventTickets: eventTicketReducer,
    genericPasses: genericPassReducer,
    users: usersReducer,
    [api.reducerPath]: api.reducer,

  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
