import { createPassUpdate } from 'components/reusable/pass';
import { EventTicket } from '../eventTicket';
import { useRetrieveEventTicketTemplateQuery, useUpdateEventTicketMutation } from 'services/eventTickets';


export const UpdateEventTicket = createPassUpdate({
  TemplateRenderer: EventTicket,
  retrieveTemplateHook: useRetrieveEventTicketTemplateQuery,
  updatePassHook: useUpdateEventTicketMutation,
  i18nNamespace: 'eventTicket',
  baseUrl: '/event-tickets/',
})