import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError, retry } from '@reduxjs/toolkit/query/react'
import { RootState } from '../app/store';
import { API_URL, CLIENT_ID, CLIENT_SECRET } from '../constants/api';
import { logout, tokensReceived } from '../features/users/usersSlice';
import { ServerAuthorization } from '../types';
import { RETRY_ATTEMPTS } from 'constants/api';


const refreshBaseQuery = fetchBaseQuery({
  baseUrl: API_URL,
})

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = (getState() as RootState).users.authorization?.accessToken
    const tokenType = (getState() as RootState).users.authorization?.tokenType
    if (token) {
      headers.set('Authorization', `${tokenType} ${token}`);
    }
    return headers
  },
})

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)
  if (result.error && result.error.status === 403) {
    // try to get a new token
    const refreshToken = (api.getState() as RootState).users.authorization?.refreshToken;
    const refreshResult = await refreshBaseQuery({
      url: `/auth/token`,
      method: 'POST',
      body: {
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
        grant_type: "refresh_token",
        refresh_token: refreshToken,
      },
    }, api, extraOptions)
    if (refreshResult.data) {
      // store the new token
      api.dispatch(tokensReceived(refreshResult.data as ServerAuthorization))
      // retry the initial query
      result = await baseQuery(args, api, extraOptions)
    } else {
      api.dispatch(logout())
    }
  }
  return result
}

const baseQueryWithRetry = retry(baseQueryWithReauth, { maxRetries: RETRY_ATTEMPTS })

export const api = createApi({
  reducerPath: 'splitApi',
  baseQuery: baseQueryWithRetry,
  tagTypes: ['BoardingPasses', 'Users', 'Coupons', 'StoreCards', 'EventTickets', 'GenericPasses', 'Scenarios', 'CreatedPasses', 'StampCoupons', 'StampCouponTransactions', 'Memberships', 'MembershipTransactions'],
  endpoints: () => ({}),
})
  