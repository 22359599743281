import { useRetrieveEventTicketTemplateQuery, useDeleteEventTicketMutation, useDeleteEventTicketTemplateMutation } from 'services/eventTickets';
import { EventTicket } from 'components/passes/eventTicket';
import { createTemplateDetails } from 'components/reusable/template';


export const EventTicketTemplateDetails = createTemplateDetails({
  TemplateRenderer: EventTicket,
  retrieveHook: useRetrieveEventTicketTemplateQuery,
  deletePassHook: useDeleteEventTicketMutation,
  deleteTemplateHook: useDeleteEventTicketTemplateMutation,
  baseUrl: '/event-tickets/',
  i18nNamespace: 'eventTicket',
})