import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import { Button } from './buttons';
import { useTranslation } from 'react-i18next';

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  gap: 10px;
  margin: 0 auto;
`;

interface LabelProps {
  readonly column?: any;
}
  
export const InputGroup = styled.div<LabelProps>`
  display: flex;
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  gap: 10px;
  justify-content: space-between;
`;

export const Label = styled.label`
  font-size: 16px;
  font-weight: bold;
`;

interface CheckboxProps {
  readonly checked: boolean;
}

export const Checkbox = styled.input<CheckboxProps>`
  width: 15px;
  height: 15px;
  border-radius: 3px;
  background-color: ${({ checked }) => (checked ? '#000000' : '#ffffff')};
  cursor: pointer;
`;


interface SliderProps {
  readonly hasError?: boolean;
}

export const Slider = styled.input<SliderProps>`
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: #ccc;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: ${({ hasError }) => (hasError ? '#ff0000' : '#000000')};
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: ${({ hasError }) => (hasError ? '#ff0000' : '#000000')};
    cursor: pointer;
  }
`;


interface InputProps {
  readonly required?: boolean;
  readonly hasError?: boolean;
}

export const Input = styled.input<InputProps>`
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  font-size: 16px;
  outline: none;

  &:focus {
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.3);
  }
`;

// Styled components
const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FakeInput = styled.input`
  display: none;
`;

const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  text-align: center;
`;

const ImagePreview = styled.img`
  max-width: 100%;
  max-height: 40px;
  padding: 0px 15px;
`;

const ButtonLabel = styled.label`
  padding: 8px 16px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  background-color: #333;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
      background-color: #111;
  }

  &:focus {
      outline: none;
      box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.3);
  }
`;

// TypeScript interface for the component props
interface ImageInputProps {
  onFileChange: (file: File | null, filename: string | null) => void;
  accept?: string;
  name?: string;
}

export const ImageInput: React.FC<ImageInputProps> = ({ onFileChange, accept, name }) => {
  const [preview, setPreview] = useState<string | null>(null);
  const { t } = useTranslation();

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    const filename = event.target.files && event.target.name;

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setPreview(reader.result as string);
      };

      reader.readAsDataURL(file);
    } else {
      setPreview(null);
    }

    onFileChange(file, filename);
  };

  return (
    <Container>
      <ButtonLabel>
        <FakeInput type="file" accept={accept} name={name} onChange={handleFileChange} />
        {t('pass:uploadImage')}
      </ButtonLabel>

      {preview && (
        <PreviewContainer>
          <ImagePreview src={preview} alt="Selected Preview" />
        </PreviewContainer>
      )}
    </Container>
  );
};