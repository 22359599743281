import type { StoreCard, CreatedPass } from '../types';
import { api } from './api';

const RESOURCE_PATH = `/templates/store_cards/`;

export const StoreCardApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listStoreCards: builder.query<StoreCard[], void>({
      query: () => RESOURCE_PATH,
      providesTags: (result) =>
      result
        ? [...result.map(({ id }) => ({ type: 'StoreCards' as const, id })), { type: 'StoreCards', id: 'LIST' }]
        : [{ type: 'StoreCards', id: 'LIST' }],
    }),
    createStoreCard: builder.mutation<StoreCard, {}>({
      query: (data: object) => ({
        url: RESOURCE_PATH,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      }),
      invalidatesTags: [{ type: 'StoreCards', id: 'LIST' }],
    }),
    retrieveStoreCard: builder.query<StoreCard, string>({
      query: (id) =>  RESOURCE_PATH + `${id}/`,
      providesTags: (res, err, id) => [{ type: 'StoreCards', id }],
    }),
    deleteStoreCard: builder.mutation<StoreCard, string>({
      query: (id) => ({
        url: RESOURCE_PATH + `${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: (res, err, id) => [{ type: 'StoreCards', id }, { type: 'StoreCards', id: 'LIST' }],
    }),
    updateStoreCard: builder.mutation<StoreCard, {id: string, updatedPassTemplate: object}>({
      query: (data) => ({
        url: RESOURCE_PATH + `${data.id}/`,
        method: 'PATCH',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data.updatedPassTemplate),
      }),
      invalidatesTags: (res, err, data) => [{ type: 'StoreCards', id: data.id }],
    }),
    generateStoreCardPass: builder.mutation<CreatedPass, {id: string, fieldsData: object}>({
      query: (data) => ({
        url: RESOURCE_PATH + `${data.id}/create_pass/`,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data.fieldsData),
      }),
      invalidatesTags: (res, err, data) => [{ type: 'StoreCards', id: data.id }, { type: 'StoreCards', id: 'LIST' }],
    }),
    updateStoreCardPass: builder.mutation<CreatedPass, {id: string, fieldsData: object}>({
      query: (data) => ({
        url: RESOURCE_PATH + `${data.id}/update_pass/`,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data.fieldsData),
      }),
      invalidatesTags: (res, err, data) => [{ type: 'StoreCards', id: data.id }, { type: 'StoreCards', id: 'LIST' }],
    }),
    deleteStoreCardPass: builder.mutation<StoreCard, {templateId: string, passId: string}>({
      query: (data) => ({
        url: RESOURCE_PATH + `${data.templateId}/delete_pass/`,
        method: 'DELETE',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({"pass_id": data.passId}),
      }),
      invalidatesTags: (res, err, data) => [{ type: 'StoreCards', id: data.templateId }, { type: 'StoreCards', id: 'LIST' }],
    }),
    sendStoreCardPassPush: builder.mutation<StoreCard, {templateId: string, passId: string, message: string}>({
      query: (data) => ({
        url: RESOURCE_PATH + `${data.templateId}/send_push/`,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({"pass_id": data.passId, "message": data.message}),
      }),
    }),
  }),
})

// Export hooks for usage in functional components, which are
export const { useListStoreCardsQuery, useCreateStoreCardMutation, useRetrieveStoreCardQuery, useDeleteStoreCardMutation, useUpdateStoreCardMutation, useGenerateStoreCardPassMutation,
  useUpdateStoreCardPassMutation, useDeleteStoreCardPassMutation, useSendStoreCardPassPushMutation } = StoreCardApi;