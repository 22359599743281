import { useListCouponsQuery } from "services/coupons";
import { Coupon } from "../coupon";
import styled from 'styled-components';
import { createTemplateDashboard } from 'components/reusable/template';


export const CouponDashboard = createTemplateDashboard({
  TemplateRenderer: Coupon,
  listTemplateHook: useListCouponsQuery,
  i18nNamespace: "coupon"
})