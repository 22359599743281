import styled from "styled-components";

interface ButtonProps {
  size?: 'small' | 'large';
  color?: string;
}

export const Button = styled.button<ButtonProps>`
  padding: ${props => props.size === 'small' ? '6px 12px' : '8px 16px'};
  font-size: ${props => props.size === 'small' ? '14px' : '16px'};
  font-weight: bold;
  border: none;
  border-radius: 4px;
  background-color: ${props => props.color || '#333'};
  color: #fff;
  cursor: pointer;

  &:hover {
      background-color: #111;
  }

  &:focus {
      outline: none;
      box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.3);
  }

  &:disabled {
    background-color: #ccc;
    color: #333;
    cursor: not-allowed;
  }
`;

export const SmallButton = styled(Button)`
  padding: 6px 12px;
  font-size: 14px;
`;

export const TransparentButton = styled(Button)`
  background-color: transparent;
  color: #333;

  &:hover {
      background-color: #ccc;
      color: #333;
  }
`;

export const SmallTransparentButton = styled(TransparentButton)`
  padding: 6px 12px;
  font-size: 14px;
`;

export const Label = styled.label`
  padding: 6px 12px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  background-color: #333;
  color: #fff;
  cursor: pointer;

  &:hover {
      background-color: #111;
  }

  &:focus {
      outline: none;
      box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.3);
  }
`;

export const SmallLabel = styled(Label)`
  padding: 6px 12px;
  font-size: 14px;
`;


export const Select = styled.select`
  padding: 8px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  background-color: #333;
  color: #fff;
  cursor: pointer;

  &:hover {
      background-color: #111;
  }

  &:focus {
      outline: none;
      box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.3);
  }
`;

export const Option = styled.option`
  padding: 8px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  background-color: #333;
  color: #fff;
  cursor: pointer;
`;

export const SmallSelect = styled(Select)`
  padding: 2px 8px;
  font-size: 14px;
`;

const Input = styled.input`
  padding: 6px 12px;
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  background-color: #333;
  color: #fff;
  cursor: pointer;

  &:hover {
      background-color: #111;
  }

  &:focus {
      outline: none;
      box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.3);
  }
`;

export const SelectButton = ({ children, ...props }) => (
  <Select {...props}>
    {children}
  </Select>
);

export const SelectOption = ({ children, ...props }) => (
  <Option {...props}>
    {children}
  </Option>
);

export const InputButton = ({ children, ...props }) => (
  <Input {...props}>
    {children}
  </Input>
);

export const SmallInputButton = styled(InputButton)`
  padding: 6px 12px;
  font-size: 14px;
`;

export const LargeInputButton = styled(InputButton)`
  padding: 8px 16px;
  font-size: 16px;
`;

export const SmallSelectButton = styled(SelectButton)`
  padding: 6px 12px;
  font-size: 14px;
`;

export const LargeSelectButton = styled(SelectButton)`
  padding: 8px 16px;
  font-size: 16px;
`;

export const SmallInput = styled(Input)`
  padding: 6px 12px;
  font-size: 14px;
`;

export const LargeInput = styled(Input)`
  padding: 8px 16px;
  font-size: 16px;
`;


export const LargeSelect = styled(Select)`
  padding: 8px 16px;
  font-size: 16px;
`;

export const SmallOption = styled(Option)`
  padding: 6px 12px;
  font-size: 14px;
`;

export const LargeOption = styled(Option)`
  padding: 8px 16px;
  font-size: 16px;
`;

export const SmallTransparentSelectButton = styled(TransparentButton)`
  padding: 6px 12px;
  font-size: 14px;
`;

export const LargeTransparentSelectButton = styled(TransparentButton)`
  padding: 8px 16px;
  font-size: 16px;
`;

export const SmallTransparentInputButton = styled(TransparentButton)`
  padding: 6px 12px;
  font-size: 14px;
`;

export const LargeTransparentInputButton = styled(TransparentButton)`
  padding: 8px 16px;
  font-size: 16px;
`;

export const LargeTransparentButton = styled(TransparentButton)`
  padding: 8px 16px;
  font-size: 16px;
`;

export const LargeButton = styled(Button)`
  padding: 8px 16px;
  font-size: 16px;
`;

export const TooltipButton = styled(SmallTransparentButton)`
  position: relative;
  display: inline-flex;

  img {
    width: 20px;
  }
`;

export const TooltipText = styled.span`
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 5px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -8px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }

  ${TooltipButton}:hover & {
    visibility: visible;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BorderedButtonGroup = styled(ButtonGroup)`
  border: 1px solid #ddd;
  padding: 4px;
  border-radius: 4px;
`;


