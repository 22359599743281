import { ScenarioContext, useScenarioContext, ScenarioContextProvider } from "contexts/scenarioContext";
import { Button } from "components/reusable/buttons";
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StampCouponActions, StampCouponState } from "components/scenarios/stampCoupons";
import { useLazyRetrieveCreatedPassBySerialQuery } from "services/createdPasses";
import styled, { css } from "styled-components";
import { ContainerWrapper } from "components/reusable/containers";
import { Table, TableRow, TableBody, TableCell, TableHead } from "components/reusable/tables";
import { BoardingPass } from "components/passes/boardingPass";
import { Coupon } from "components/passes/coupon";
import { StoreCard } from "components/passes/storeCard";
import { GenericPass } from "components/passes/genericPass";
import { EventTicket } from "components/passes/eventTicket";
import { Scanner } from '@yudiel/react-qr-scanner';
import { toast } from 'react-toastify';
import { useLazyGetCouponTransactionsQuery, useLazyGetMembershipBySerialQuery, useLazyGetMembershipTransactionsQuery } from "services/scenarios";
import {
  SmallTransparentButton,
  SmallLabel,
  SmallOption,
  SmallSelect,
} from 'components/reusable/buttons';
import { PaginationContainer } from 'components/reusable/passTable';
import { MembershipActions, MembershipState } from "components/scenarios/memberships";


const TopSideContainer = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
`;

const QuadContainerStyle = css`
  width: 50%;
  height: 100%;
  text-align: left;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: #f5f5f5;
  padding: 20px 10px;
  margin: 10px 5px;
`;

const ScenarioStateContainer = styled.div`
  ${QuadContainerStyle}
`;

const UserInfoContainer = styled.div`
  ${QuadContainerStyle}
  
  display: flex;
  flex-direction: row;
  align-content: start;
`;

const ScenarioContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90vh;

  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
  @media (min-width: 1400px) {
    max-width: 1320px;
  }
  @media (min-width: 1680px) {
    max-width: 1560px;
  }
`;

const defaultScenarioType = "stamp_coupons";


const getPassRenderer = (passType: string) => {
  switch (passType) {
    case "boarding-passes":
      return BoardingPass;
    case "coupons":
      return Coupon;
    case "store-cards":
      return StoreCard;
    case "generic-passes":
      return GenericPass;
    case "event-tickets":
      return EventTicket;
    default:
      return BoardingPass;
  }
}


const UserInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  width: 100%;

  button {
    margin-top: 10px;
  }
`;

const ParametersContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  text-align: left;
  box-sizing: border-box;
  justify-content: space-between;
  margin: 10px 5px;
`;

const PassRendererContainer = styled.div`
  width: 40%;
  text-align: right;
  box-sizing: border-box;
  margin: auto 0;
`;

const ScannerContainer = styled.div`
  width: 150px;
  height: 150px;
  align-self: start;
  border-radius: 10px;
  background-color: #f5f5f5;
`;


const TransactionTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  table {
    margin: auto 0;
  };
`;


const UserInfo = ({passType}) => {
  const { createdPass, setCreatedPass, passTemplate } = useScenarioContext();
  const { t } = useTranslation();
  const [getCreatedPass, { isSuccess, isError, data, currentData,  requestId }] = useLazyRetrieveCreatedPassBySerialQuery();
  const [serialNumber, setSerialNumber] = useState("");

  const handleGetUserInfo = () => {
    getCreatedPass(serialNumber);
  }

  const handleScan = (result) => {
    setSerialNumber(result);
    getCreatedPass(result);
  }
  
  useEffect(() => {
    if (isSuccess && data) {
      setCreatedPass(data);
    }
    if (isSuccess && currentData) {
      setCreatedPass(currentData);
    }
    if (isError) {
      toast.error("User not found", { autoClose: 5000 });
    }
  }, [isSuccess, isError, requestId, currentData]);

  return (
    <UserInfoContainer>
      <ParametersContainer>
        { createdPass ? (
          <Table>
            <h2>{t('userInfo')}</h2>
            <TableBody>
              <TableRow>
                <TableCell>{t('serialNumber')}</TableCell>
                <TableCell>{createdPass.serial_number}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('passType')}</TableCell>
                <TableCell>{createdPass.pass_type_identifier}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('passId')}</TableCell>
                <TableCell>{createdPass.id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('updatedAt')}</TableCell>
                <TableCell>{new Date(createdPass.updated_at).toLocaleString()}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        ) : (
        <UserInputContainer>
            <h2>{t('noSelectedUser')}</h2>
            <p>{t('scanToProceed')}</p>
            <input type="text" placeholder="Enter serial number" value={serialNumber} onChange={(e) => setSerialNumber(e.target.value)} />
            <Button onClick={handleGetUserInfo} disabled={!serialNumber}>Get User Info</Button>
          </UserInputContainer>
        )}
        <ScannerContainer>
          <Scanner onResult={handleScan} styles={{
              container: {
                width: "150px",
                height: "150px",
              },
              finderBorder: 5,
            }}
            options={{
              constraints: {
                facingMode: 'environment',
                aspectRatio: { ideal: 1 },
              },
            }}
          />
        </ScannerContainer>
      </ParametersContainer>
      { passTemplate && passType && (
        <PassRendererContainer>
          {getPassRenderer(passType)({ pass: passTemplate, createdPass})}
        </PassRendererContainer>
      )}
    </UserInfoContainer>
  )
};



const ScenarioTransactionsTable = ({ createdPass, getTransactionHook }) => {
  const [getTransactions, { isSuccess, isError, data }] = getTransactionHook();
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(1);
  const { t } = useTranslation();

  useEffect(() => {
    if (createdPass) {
      getTransactions({sn: String(createdPass.serial_number), size, page});
    }
  }, [createdPass, size, page]);

  const handleChangeRowsPerPage = (event) => {
    setSize(+event.target.value);
    setPage(1);
  }

  return (
    <>
      {isSuccess && data && (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('ID')}</TableCell>
                <TableCell>{t('Transaction Type')}</TableCell>
                <TableCell>{t('Value')}</TableCell>
                <TableCell>{t('Timestamp')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.results.map((transaction) => (
                <TableRow key={transaction.id}>
                  <TableCell>{transaction.id}</TableCell>
                  <TableCell>{transaction.type}</TableCell>
                  <TableCell>{transaction.value}</TableCell>
                  <TableCell>{new Date(transaction.date).toLocaleString()}</TableCell>
                </TableRow>
              ))}
              {data.results.length === 0 && (
                <TableRow>
                  <TableCell colSpan={4}>{t('noTransactions')}</TableCell>
                </TableRow>
              )}
              {data.results.length > 0 && data.results.length < size && (
                <TableRow style={{height: 33 * (size - data.results.length)}}>
                  <TableCell colSpan={4}></TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <PaginationContainer>
            <SmallLabel>
              {t('pass:rowsPerPage')}
              <SmallSelect value={size} onChange={handleChangeRowsPerPage}>
                <SmallOption value={5}>5</SmallOption>
                <SmallOption value={10}>10</SmallOption>
              </SmallSelect>
            </SmallLabel>
            <SmallTransparentButton onClick={() => setPage(page - 1)} disabled={page === 1}>{t('pass:prevPage')}</SmallTransparentButton>
            <SmallTransparentButton onClick={() => setPage(page + 1)} disabled={page >= Math.ceil(data.count / size)}>{t('pass:nextPage')}</SmallTransparentButton>
          </PaginationContainer>
        </>
      )}
      { !createdPass && <p>{t('noTransactions')}</p>}
    </>
  )
}



const StampCouponTransactions = () => {
  const { createdPass } = useScenarioContext();
  const { t } = useTranslation();

  return (
    <TransactionTableContainer>
      <h2>{t('transactions')}</h2>
      <ScenarioTransactionsTable createdPass={createdPass} getTransactionHook={useLazyGetCouponTransactionsQuery} />
    </TransactionTableContainer>
  )
}





const MembershipTransactions = () => {
  const { createdPass } = useScenarioContext();
  const { t } = useTranslation();

  return (
    <TransactionTableContainer>
      <h2>{t('transactions')}</h2>
      <ScenarioTransactionsTable createdPass={createdPass} getTransactionHook={useLazyGetMembershipTransactionsQuery} />
    </TransactionTableContainer>
  )
}



const ScenarioTransactions = () => {
  const { scenarioType } = useScenarioContext();
  const { t } = useTranslation();
  return (
    <ScenarioStateContainer>
      {{
        'memberships': <MembershipTransactions/>,
        'stamp_coupons': <StampCouponTransactions />,
      }[scenarioType || defaultScenarioType] || <h2>{t('noTransactionsAvailable')}</h2>}
    </ScenarioStateContainer>
  )
}

const ScenarioActions = () => {
  const { scenarioType } = useScenarioContext();
  const { t } = useTranslation();
  return (
    <ScenarioStateContainer>
      {{
        'memberships': <MembershipActions />,
        'stamp_coupons': <StampCouponActions />,
      }[scenarioType || defaultScenarioType] || <h2>{t('noActionsAvailable')}</h2>}
    </ScenarioStateContainer>
  )
};

const ScenarioState = () => {
  const { scenarioType } = useScenarioContext();
  const { t } = useTranslation();

  return (
    <ScenarioStateContainer>
    {{
      'memberships': <MembershipState />,
      'stamp_coupons': <StampCouponState />,
    }[scenarioType || defaultScenarioType] || <h2>{t('noActionsAvailable')}</h2>}   
    </ScenarioStateContainer>
  )
};


export const ScenarioPage = () => {
  const [createdPass, setCreatedPass] = useState(null);
  const { passType, passId, scenarioId, scenarioType } = useParams<{ passType: string, passId: string, scenarioId: string, scenarioType: string}>();
  const { state } = useLocation();
  const { passTemplate } = state as { passTemplate: any };

  return (
    <ScenarioContextProvider scenarioId={scenarioId} scenarioType={scenarioType} passTemplate={passTemplate}>
      <ContainerWrapper>
        <ScenarioContainer>
          <TopSideContainer>
            <UserInfo passType={passType}/>
            <ScenarioState />
          </TopSideContainer>
          <TopSideContainer>
            <ScenarioTransactions />
            <ScenarioActions />
          </TopSideContainer>
        </ScenarioContainer>
      </ContainerWrapper>
    </ScenarioContextProvider>
  )
};
