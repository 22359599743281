import { useUpdateCouponPassMutation, useRetrieveCouponQuery } from 'services/coupons';
import { Coupon } from '../coupon';
import { CouponInputs } from 'types';
import { createPassUpdate } from 'components/reusable/pass';


export const UpdateCouponPass = createPassUpdate<CouponInputs>({
  TemplateRenderer: Coupon,
  retrieveTemplateHook: useRetrieveCouponQuery,
  updatePassHook: useUpdateCouponPassMutation,
  i18nNamespace: 'coupon',
  baseUrl: '/coupons/',
})