import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Coupon } from '../../types';


export interface CouponState {
    passes: Coupon[];
    selectedPass: Coupon | null;
    status: 'idle' | 'loading' | 'failed';
}

const initialState: CouponState = {
    passes: [],
    selectedPass: null,
    status: 'loading',
};


export const CouponsSlice = createSlice({
  name: 'coupons',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    received: (state, action: PayloadAction<Coupon[]>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.passes = action.payload;
    },
    created: (state, action: PayloadAction<Coupon>) => {
      state.passes.concat(action.payload);
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    deleted: (state, action: PayloadAction<Coupon>) => {
      let index = state.passes.indexOf(action.payload)
      state.passes = state.passes.splice(index, 1);
    },
  },
});

export const { received, created, deleted } = CouponsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectPasses = (state: RootState) => state.coupons.passes.values;

export default CouponsSlice.reducer;
