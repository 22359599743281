import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';
import { useLoginMutation } from 'services/users';
import styled from 'styled-components';


const LoginPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const LoginFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
`;

const FormTitle = styled.h2`
  text-align: center;
  margin-bottom: 20px;
`;

const FormField = styled.input`
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const SubmitButton = styled.button`
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const LinksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  a {
    text-decoration: none;
    color: #007bff;
  }

  a:hover {
    text-decoration: underline;
  }
`;


const LoginPage = () => {

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { t } = useTranslation('auth');
  let navigate = useNavigate();

  const [login, { isSuccess, isError, error }] = useLoginMutation();

  useEffect(() => {
    // Update the document title using the browser API
    if(isSuccess) {
      navigate("/backoffice/", { replace: true });
    }
  }, [isSuccess, navigate]);

  useEffect(() => {
    if(isError) {
      console.log("Error: ", error);
    }
  }, [isError]);

  const submitLogin = () => {
    if (username && password) {
      login({ username, password });
    }
  }

  return (
    <LoginPageContainer>
      <LoginFormContainer >
        <FormTitle>Login</FormTitle>
        <FormField type="text" placeholder="Username" onChange={e => setUsername(e.target.value)}/>
        <FormField type="password" placeholder="Password" onChange={e => setPassword(e.target.value)}/>
        <SubmitButton type="submit" onClick={submitLogin}>Login</SubmitButton>
        <LinksContainer>
          <Link to={'/sign-up/'}>Sign Up</Link>
          <Link to={'/forgot-password/'}>Forgot Password</Link>
        </LinksContainer>
      </LoginFormContainer>
    </LoginPageContainer>
  );
};

export default LoginPage;