import { useRetrieveBoardingPassQuery, usePatchBoardingPassMutation } from 'services/boardingPasses';
import { BoardingPass } from '../boardingPass';
import { BoardingPassInputs } from 'types';
import { createTemplateEdit } from 'components/reusable/template';


export const EditBoardingPassTemplate = createTemplateEdit<BoardingPassInputs>({
  TemplateRenderer: BoardingPass,
  retrieveHook: useRetrieveBoardingPassQuery,
  patchHook: usePatchBoardingPassMutation,
  i18nNamespace: 'boardingPass',
  baseUrl: '/boarding-passes/',
})
