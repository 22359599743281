import i18n from '../../i18n';

const navbarTranslation = {
    ru: {
        "boardingPasses": "Посадочные талоны",
        "coupons": "Купоны",
        "storeCards": "Карты магазинов",
        "eventTickets": "Билеты",
        "genericPasses": "Общие пропуски",
        "list": "Список",
        "create": "Создать",
        "signin": "Войти",
        "signup": "Зарегистрироваться",
        "logout": "Выйти",
        "as": "как",
    },
    en: {
        "boardingPasses": "Boarding Passes",
        "coupons": "Coupons",
        "storeCards": "Store Cards",
        "eventTickets": "Event Tickets",
        "genericPasses": "Generic Passes",
        "list": "List",
        "create": "Create",
        "signin": "Sign In",
        "signup": "Sign Up",
        "logout": "Logout",
        "as": "as",
    }
}
i18n
    .addResourceBundle('ru', 'navbar', navbarTranslation.ru)
    .addResourceBundle('en', 'navbar', navbarTranslation.en)