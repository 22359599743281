import { useRetrieveEventTicketTemplateQuery, useUpdateEventTicketTemplateMutation } from 'services/eventTickets';
import { EventTicket } from 'components/passes/eventTicket';
import { createTemplateEdit } from 'components/reusable/template';


export const EditEventTicketTemplate = createTemplateEdit({
  TemplateRenderer: EventTicket,
  retrieveHook: useRetrieveEventTicketTemplateQuery,
  patchHook: useUpdateEventTicketTemplateMutation,
  baseUrl: '/event-tickets/',
  i18nNamespace: 'eventTicket',
})