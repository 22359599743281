import { createPassUpdate } from 'components/reusable/pass';
import { GenericPass } from 'components/passes/genericPass';
import { useRetrieveGenericPassTemplateQuery, useUpdateGenericPassMutation } from 'services/genericPasses';
import { GenericPassInputs } from 'types';


export const UpdateGenericPass = createPassUpdate<GenericPassInputs>({
  TemplateRenderer: GenericPass,
  retrieveTemplateHook: useRetrieveGenericPassTemplateQuery,
  updatePassHook: useUpdateGenericPassMutation,
  i18nNamespace: 'genericPass',
  baseUrl: '/generic-passes/',
})