import { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/reusable/buttons';
import AirportField from 'components/reusable/templateFields';
import { Input, Label, InputGroup } from 'components/reusable/inputs';
import { CreatedPass, Field, UserInfo } from 'types';
import { TemplateType } from 'interfaces';
import { ContainerWrapper, InputsWrapper, TemplateBlock, TemplateRendererWrapper } from 'components/reusable/containers';


interface UpdatePassProps {
  TemplateRenderer: any;
  retrieveTemplateHook: (id: string) => any;
  updatePassHook: () => any;
  baseUrl: string;
  i18nNamespace: string;
}


export const createPassUpdate = <T extends TemplateType>({ TemplateRenderer, retrieveTemplateHook, updatePassHook, i18nNamespace, baseUrl }: UpdatePassProps) => {
  const UpdatePass = () => {
    const { t } = useTranslation(i18nNamespace);
    const navigate = useNavigate();
    const { id, passId } = useParams();
    const { data, isLoading } = retrieveTemplateHook(String(id));
    const [updatePass, result] = updatePassHook();

    const [inputs, setInputs] = useState({ field_values: {} });
    const [passTemplate, setPassTemplate] = useState({} as T);
    const [createdPass, setCreatedPass] = useState({} as CreatedPass);

    const handleSubmit = (event: any) => {
      event.preventDefault();
      const payload = {
        pass_id: passId,
        ...inputs,
      }
      updatePass({ id: String(id), fieldsData: payload });
    };

    useEffect(() => {
      if(result.isSuccess) {
        window.open(result.data.data, '_blank', 'noopener,noreferrer');
        navigate(`/backoffice${baseUrl}${id}/` , { replace: true });
      }
      if(!isLoading && data) {
        const newPass = data?.created_passes.find((pass) => String(pass.id) === passId) as CreatedPass
        setCreatedPass(newPass)
        setPassTemplate(injectUserInfo(newPass.user_info, data) as T);
      }
    }, [result.isSuccess, result?.data?.data, isLoading]);

    const handleChange = (event: any) => {
      const key = event.target.id;
      const name = event.target.name;
      const value = event.target.value;
      setInputs(values => ({...values, field_values: {
        ...values.field_values, [key]: {[name]: value}
      }}));
    }

    const injectUserInfo = (userInfo: UserInfo | null, passTemplate: T) => {
      if (!userInfo || !passTemplate) {
        return;
      }
      const fieldValues = userInfo?.['field_values'];
      const newPassTemplate = { ...passTemplate };
      const injectField = (field) => {
        if(fieldValues?.[field?.key]) {
          if(fieldValues[field.key].label) {
            field = { ...field, label: fieldValues[field.key].label };
          }
          if(fieldValues[field.key].value) {
            field = { ...field, value: fieldValues[field.key].value };
          }
        }
        return field;
      }

      newPassTemplate.header_fields = newPassTemplate?.header_fields?.map(injectField);
      newPassTemplate.secondary_fields = newPassTemplate?.secondary_fields?.map(injectField);
      newPassTemplate.auxiliary_fields = newPassTemplate?.auxiliary_fields?.map(injectField);
      newPassTemplate.back_fields = newPassTemplate?.back_fields?.map(injectField);
      newPassTemplate.origin_field = injectField(newPassTemplate.origin_field);
      newPassTemplate.destination_field = injectField(newPassTemplate.destination_field);
      return(newPassTemplate as T)
    }

    if(isLoading) {
      return(<div>{t('Loading')}</div>)
    } else {
      return (
        <ContainerWrapper>
          <div className="container text-center">
            <div className="row justify-content-start">
              <div className="col-3">
                { passTemplate && (
                  <TemplateBlock>
                    <TemplateRendererWrapper>
                      <TemplateRenderer pass={injectUserInfo(inputs, passTemplate)} createdPass={createdPass} />
                    </TemplateRendererWrapper>
                    <TemplateRendererWrapper>
                      <TemplateRenderer.Back pass={injectUserInfo(inputs, passTemplate)} createdPass={createdPass} />
                    </TemplateRendererWrapper>
                  </TemplateBlock>
                )}
              </div>
              <div className="col-9">
                <InputsWrapper>
                  <form onSubmit={handleSubmit} >
                    { (passTemplate?.header_fields?.length !== 0) &&
                    <div>
                      <Typography variant="h5" component="h5">{t('headerFields')}</Typography>
                      { passTemplate?.header_fields?.map((field) => {
                          return(
                            <InputGroup column key={field.key}>
                              <Label>{field.label}</Label>
                              <Input id={field.key} key={field.key} name={'value'} defaultValue={field.value} onChange={handleChange} />
                            </InputGroup>
                          )
                        })
                      }
                    </div>
                    }
                    { 'transit_type' in passTemplate && (
                      <>
                        <div>
                          <Label>{t('originFields')}</Label>
                          <AirportField field={passTemplate?.origin_field as Field} onChangeCallback={newValue => setInputs(v => ({...v, field_values: {...v.field_values, ...newValue }}))} />
                        </div>
                        <div>
                          <Label>{t('destinationFields')}</Label>
                          <AirportField field={passTemplate?.destination_field as Field} onChangeCallback={newValue => setInputs(v => ({...v, field_values: {...v.field_values, ...newValue }}))} />
                        </div>
                      </>
                    )}
                    { 'primary_field' in passTemplate && (
                      <>
                        <Label>{t('primaryField')}</Label>
                        <InputGroup column>
                          <Label>{passTemplate?.primary_field?.label}</Label>
                          <Input id={passTemplate?.primary_field?.key} key={passTemplate?.primary_field?.key} name={'value'} defaultValue={passTemplate?.primary_field?.value} onChange={handleChange} />
                        </InputGroup>
                      </>
                    )}
                    { (passTemplate?.secondary_fields?.length !== 0) && (
                        <>
                          <Typography variant="h5" component="h5">{t('secondaryFields')}</Typography>
                          { passTemplate?.secondary_fields?.map((field) => {
                              return(
                                <InputGroup column key={field.key}>
                                  <Label>{field.label}</Label>
                                  <Input id={field.key} key={field.key} name={'value'} defaultValue={field.value} onChange={handleChange} />
                                </InputGroup>
                              )
                            })
                          }
                        </>
                      )
                    }
                    { (passTemplate?.auxiliary_fields?.length !== 0) && (
                        <>
                          <Typography variant="h5" component="h5">{t('auxiliaryFields')}</Typography>
                          { passTemplate?.auxiliary_fields?.map((field) => {
                              return(
                                <InputGroup column key={field.key}>
                                  <Label>{field.label}</Label>
                                  <Input id={field.key} key={field.key} name={'value'} defaultValue={field.value} onChange={handleChange} />
                                </InputGroup>
                              )
                            })
                          }
                        </>
                      )
                    }
                    { (passTemplate?.back_fields?.length !== 0) && (
                        <>
                          <Typography variant="h5" component="h5">{t('backFields')}</Typography>
                          { passTemplate?.back_fields?.map((field) => {
                              return(
                                <InputGroup column key={field.key}>
                                  <Label>{field.label}</Label>
                                  <Input id={field.key} key={field.key} name={'value'} defaultValue={field.value} onChange={handleChange} />
                                </InputGroup>
                              )
                            })
                          }
                        </>
                      )
                    }
                    <Button>{t('updatePass')}</Button>
                  </form>
                </InputsWrapper>
              </div>
            </div>
          </div>
        </ContainerWrapper>
      );
    }
  }
  return UpdatePass;
}  
