import { useRetrieveStoreCardQuery, useDeleteStoreCardPassMutation, useDeleteStoreCardMutation } from 'services/storeCards';
import { StoreCard } from '../storeCard';
import { createTemplateDetails } from 'components/reusable/template';


export const StoreCardDetails = createTemplateDetails({
  TemplateRenderer: StoreCard,
  retrieveHook: useRetrieveStoreCardQuery,
  deletePassHook: useDeleteStoreCardPassMutation,
  deleteTemplateHook: useDeleteStoreCardMutation,
  baseUrl: '/store-cards/',
  i18nNamespace: 'storeCard',
})

