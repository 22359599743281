import { createTemplateEdit } from "components/reusable/template";
import { GenericPass } from "components/passes/genericPass";
import { useRetrieveGenericPassTemplateQuery, useUpdateGenericPassTemplateMutation } from "services/genericPasses";

export const EditGenericPassTemplate = createTemplateEdit({
  i18nNamespace: "genericPass",
  TemplateRenderer: GenericPass,
  retrieveHook: useRetrieveGenericPassTemplateQuery,
  patchHook: useUpdateGenericPassTemplateMutation,
  baseUrl: "/generic-passes/",
});