import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useRetrieveBoardingPassQuery, useSendBoardingPassPushMutation } from "services/boardingPasses";
import { CreatedPass } from "types";
import { Input, Label, InputGroup } from 'components/reusable/inputs';
import { Button } from "components/reusable/buttons";
import styled from "styled-components";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: 20px;
`;

export const SendPush = (props) => {
  const { t } = useTranslation();
  const { id, passId } = useParams();
  const navigate = useNavigate();
  const { data, isLoading } = useRetrieveBoardingPassQuery(String(id));
  const [ sendPush, result ] = useSendBoardingPassPushMutation();

  const [pass, setPass] = useState({} as CreatedPass);
  const [input, setInput] = useState({
    message: '',
  });

  useEffect(() => {
    console.log(data);
    if(!isLoading && data) {
      const newPass = data?.created_passes.find((pass) => String(pass.id) === passId) as CreatedPass
      setPass(newPass);
    }
    if(result.isSuccess) {
      navigate(`/backoffice/boarding-passes/${id}/` , { replace: true });
    }
  }
  , [data, isLoading, result]);

  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setInput(values => ({...values, [name]: value}));
  }

  const handleSubmit = (event: any) => {
    event.preventDefault();
    sendPush({templateId: id as string, passId: passId as string, message: input.message});
  }

  return (
    <Form onSubmit={handleSubmit}>
      <h1>Send Push</h1>
      <p>Send Push to {pass?.pass_type_identifier} {pass?.serial_number}</p>
      <InputGroup column>
        <Label>{t("pass:pushMessage")}</Label>
        <Input id={pass.id} name={'message'} defaultValue={input.message} placeholder="Write message here..." onChange={handleChange} />
      </InputGroup>
      <Button type="submit">{t('boardingPass:updatePass')}</Button>
    </Form>
  )
}