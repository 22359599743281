import { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Page from '../../../page'
import { Typography, Box, TextField, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGenerateBoardingPassMutation, useRetrieveBoardingPassQuery } from 'services/boardingPasses';
import { BoardingPass } from '../boardingPass';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { BoardingPassInputs, Field } from 'types';
import { createPassComponent } from 'components/reusable/pass';


export const AdminGenerateBoardingPass = createPassComponent<BoardingPassInputs>({
  isAdminView: true,
  TemplateRenderer: BoardingPass,
  successUrl: '/backoffice/boarding-passes/',
  i18nNamespace: 'boardingPass',
  retrievePassTemplateHook: useRetrieveBoardingPassQuery,
  createPassHook: useGenerateBoardingPassMutation,
});

export const GenerateBoardingPass = createPassComponent<BoardingPassInputs>({
  isAdminView: false,
  TemplateRenderer: BoardingPass,
  successUrl: '/',
  i18nNamespace: 'boardingPass',
  retrievePassTemplateHook: useRetrieveBoardingPassQuery,
  createPassHook: useGenerateBoardingPassMutation,
});