import { useUpdateStoreCardPassMutation, useRetrieveStoreCardQuery } from 'services/storeCards';
import { StoreCard } from 'components/passes/storeCard';
import { StoreCardInputs } from 'types';
import { createPassUpdate } from 'components/reusable/pass';


export const UpdateStoreCard = createPassUpdate<StoreCardInputs>({
  TemplateRenderer: StoreCard,
  retrieveTemplateHook: useRetrieveStoreCardQuery,
  updatePassHook: useUpdateStoreCardPassMutation,
  i18nNamespace: 'storeCard',
  baseUrl: '/store-cards/',
})