import { Typography } from '@mui/material';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Spinner from 'components/reusable/loaders';
import { Button } from 'components/reusable/buttons';


const ContainerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
  a {
    text-decoration: none;
    color: #ffffff;
  }
`;

interface TextProps {
  color?: string;
  size?: string;
}

const Text = styled.p<TextProps>`
  color: #000000;
  text-align: center;
  font-size: ${(props) => props.size || '1.5rem'};
  color: ${(props) => props.color || '#ffffff'};
`


const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  position: bottom;
  bottom: 0;
  width: 100%;
`;

const ElementWrapper = styled.div`
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  pointer-events: none;
`;

interface TemplateDashboardProps {
  TemplateRenderer: any;
  listTemplateHook: () => any;
  i18nNamespace: string;
}


export const createTemplateDashboard = ({TemplateRenderer, listTemplateHook, i18nNamespace = "pass"}: TemplateDashboardProps) => {
  const TemplateDashboard = () => {
    const { data, isLoading } = listTemplateHook();
    const { t } = useTranslation(i18nNamespace);

    return (
      <ContainerWrapper>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            { data.length === 0 && (
              <Typography>{t("noAvailablePasses")}</Typography>
            )}
            { data.length > 0 && (
              data.map((element: any, index: number) => {
                return(
                  <div key={element.verbose_name + index}>             
                    <Link to={element.id.toString() + '/'}>
                      <Text color="black">{element.verbose_name}</Text>
                      <ElementWrapper>
                        <TemplateRenderer pass={element} />
                      </ElementWrapper>
                    </Link>
                  </div>
                )
              })
            )}
            <ButtonWrapper>
              <Link to='create/'>
                <Button>{t('createTemplate')}</Button>
              </Link>
            </ButtonWrapper>
          </>
        )}
      </ContainerWrapper>
    )
  }
  return TemplateDashboard;
}