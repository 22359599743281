import { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import dayjs from 'dayjs';
import { Typography, TextField, Checkbox, FormControlLabel, Slider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Field } from 'types';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CompactPicker } from 'react-color';
import { selectRealm } from 'features/users/usersSlice';
import { useSelector } from 'react-redux';
import { FieldEdit, FieldListEdit, IBeaconListEdit, LocationListEdit, BarcodeListEdit } from 'components/passes/helpers';
import { Button, Select, Option, Label as SelectLabel } from 'components/reusable/buttons';
import { Label } from 'components/reusable/inputs';
import { ImageInput, Input, InputGroup } from 'components/reusable/inputs';
import { TemplateType } from 'interfaces';
import { InputsWrapper, TemplateBlock, TemplateRendererWrapper } from 'components/reusable/containers';


interface EditTemplateProps {
  TemplateRenderer: any;
  retrieveHook: (id: string) => any;
  patchHook: () => any;
  baseUrl: string;
  i18nNamespace: string;
}


export const createTemplateEdit = <T extends TemplateType>({TemplateRenderer, retrieveHook, patchHook, i18nNamespace, baseUrl}: EditTemplateProps) => {
  const EditTemplate = () => {
    const { t } = useTranslation(i18nNamespace);
    const { id } = useParams();
    const realm = useSelector(selectRealm);
    const { data, isLoading } = retrieveHook(String(id));
    const [ passTemplate, setPassTemplate ] = useState<T>({
      owner: realm?.id as String,
      allow_anonymous: false,
      verbose_name: '',
      description: '',
      app_launch_url: null,
      associated_store_identifiers: null,
      max_distance: 550,
      background_color: "#ffffff",
      foreground_color: "#000000",
      label_color: "#000000",
      expiration_date: dayjs(),
      relevant_date: dayjs(),
      logo_text: '',
      header_fields: [],
      back_fields: [],
      secondary_fields: [],
      auxiliary_fields: [],
      ibeacons: [],
      locations: [],
      barcodes: [],
      logo_image: undefined,
      icon_image: undefined,
    } as unknown as T);

    const [ updatePass, { isSuccess } ] = patchHook();
    const navigate = useNavigate();

    useEffect(() => {
      if (realm?.id) {
        setPassTemplate(values => ({...values, owner: realm?.id as String}));
      }
    }, [realm?.id])

    useEffect(() => {
      if (!isLoading && data) {
        setPassTemplate(values => ({
          ...values,
          ...data,
          relevant_date: data?.relevant_date ? dayjs(data?.relevant_date) : null,
          expiration_date: data?.expiration_date ? dayjs(data?.expiration_date) : null,
        } as T))
      }
      if (isSuccess) {
        navigate(`/backoffice${baseUrl}${id}/`)
      }
    }, [isLoading, isSuccess])

    const setField = (key: string) => {
      return function(value: Field) {
        setPassTemplate(values => ({...values, [key]: value}))
      }
    }

    const setFields = (key: string) => {
      return function(value: Field[]) {
        setPassTemplate(values => ({...values, [key]: value}))
      }
    }

    const handleChange = (event: any) => {
      setPassTemplate(values => ({...values, [event.target.name]: event.target.value}));
    }

    const getBase64 = (file: any, cb: any) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
          cb(reader.result)
      };
    }

    const handleFileFieldChange = (file: any, filename: string | null) => {
      if (file) {
        getBase64(file, (result: string) => {
          setPassTemplate(value => ({...value, [filename || "image.png"]: result}));
        });
      }
    }

    const handleSubmit = (event: any) => {
      event.preventDefault();
      updatePass({
        'id': String(id),
        'updatedPassTemplate': {...passTemplate,
          relevant_date: passTemplate.relevant_date?.toISOString(),
          expiration_date: passTemplate.expiration_date?.toISOString(),
        }
      })
    };

    const handleChecked = (event: any) => {
      const name = event.target.name;
      const value = event.target.checked;
      setPassTemplate(values => ({...values, [name]: value}));
    }

    if (isLoading) {
      return <div>Loading...</div>
    } else {
      return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Typography gutterBottom variant="h5" component="h2">{t('editTemplate')}</Typography>
          <div className="container text-center">
            <div className="row justify-content-start">
              <div className="col-3">
                <TemplateBlock>
                  <TemplateRendererWrapper>
                    <TemplateRenderer pass={{...data, ...passTemplate}} />
                  </TemplateRendererWrapper>
                  <TemplateRendererWrapper>
                    <TemplateRenderer.Back pass={{...data, ...passTemplate}} />
                  </TemplateRendererWrapper>
                </TemplateBlock>
              </div>
              <div className="col-9">
                <InputsWrapper>
                  <form onSubmit={handleSubmit}>
                    <div>
                      <FormControlLabel control={<Checkbox name='allow_anonymous' onChange={handleChecked} defaultChecked />} label={t('allowAnonymous')} />
                    </div>
                    { 'transit_type' in passTemplate && (
                      <SelectLabel>
                        {t('transitType')}
                        <Select
                          value={passTemplate?.transit_type || data?.transit_type}
                          name="transit_type"
                          onChange={handleChange}
                        >
                          <Option value={'PKTransitTypeAir'}>{t('AIR')}</Option>
                          <Option value={'PKTransitTypeTrain'}>{t('TRAIN')}</Option>
                          <Option value={'PKTransitTypeBus'}>{t('BUS')}</Option>
                          <Option value={'PKTransitTypeBoat'}>{t('BOAT')}</Option>
                          <Option value={'PKTransitTypeGeneric'}>{t('GENERIC')}</Option>
                        </Select>
                      </SelectLabel>
                    )}
                    <div>
                      <InputGroup column>
                        <Label>{t('verboseName')}</Label>
                        <Input required name='verbose_name' value={passTemplate?.verbose_name} onChange={handleChange} />
                      </InputGroup>
                      <InputGroup column>
                        <Label>{t('description')}</Label>
                        <Input name='description' value={passTemplate?.description} onChange={handleChange} />
                      </InputGroup>
                      <InputGroup column>
                        <Label>{t('appLaunchUrl')}</Label>
                        <Input name='app_launch_url' value={passTemplate?.app_launch_url || undefined} onChange={handleChange} />
                      </InputGroup>
                      <InputGroup column>
                        <Label>{t('associatedStoreID')}</Label>
                        <Input name='associated_store_identifiers' value={passTemplate?.associated_store_identifiers || undefined} onChange={handleChange} />
                      </InputGroup>
                      <InputGroup column>
                        <Label>{t('logoText')}</Label>
                        <Input name='logo_text' value={passTemplate?.logo_text} onChange={handleChange} />
                      </InputGroup>
                      <InputGroup column>
                        <Label>{t('maxDistance')}</Label>
                        <Slider name='max_distance' min={100} max={999} value={passTemplate?.max_distance} valueLabelDisplay='auto' step={50} onChange={handleChange} />
                      </InputGroup>
                      <DesktopDateTimePicker label={t('expirationDate')} value={passTemplate?.expiration_date} onChange={(e) => (setPassTemplate(values => ({...values, expiration_date: e})))} renderInput={(params) => <TextField {...params} />} />
                      <DesktopDateTimePicker label={t('relevantDate')} value={passTemplate?.relevant_date} onChange={(e) => (setPassTemplate(values => ({...values, relevant_date: e})))} renderInput={(params) => <TextField {...params} />} />
                    </div>
                    <div>
                      <div style={{margin: "15px 0px"}}>
                        <Typography variant="h6">
                          {t('logoImage')}
                        </Typography>
                        <ImageInput name='logo_image' accept="image/png" onFileChange={handleFileFieldChange} />
                      </div>
                      <div style={{margin: "15px 0px"}}>
                        <Typography variant="h6">
                          {t('iconImage')}
                        </Typography>
                        <ImageInput name='icon_image' accept="image/png" onFileChange={handleFileFieldChange} />
                      </div>
                      { 'footer_image' in passTemplate && (
                        <div style={{margin: "15px 0px"}}>
                          <Typography variant="h6">
                            {t('footerImage')}
                          </Typography>
                          <ImageInput name='footer_image' accept="image/png" onFileChange={handleFileFieldChange} />
                        </div>
                      )}
                      { 'background_image' in passTemplate && (
                        <div style={{margin: "15px 0px"}}>
                          <Typography variant="h6">
                            {t('backgroundImage')}
                          </Typography>
                          <ImageInput name='background_image' accept="image/png" onFileChange={handleFileFieldChange} />
                        </div>
                      )}
                      { 'strip_image' in passTemplate && (
                        <div style={{margin: "15px 0px"}}>
                          <Typography variant="h6">
                            {t('stripImage')}
                          </Typography>
                          <ImageInput name='strip_image' accept="image/png" onFileChange={handleFileFieldChange} />
                        </div>
                      )}
                      { 'thumbnail_image' in passTemplate && (
                        <div style={{margin: "15px 0px"}}>
                          <Typography variant="h6">
                            {t('thumbnailImage')}
                          </Typography>
                          <ImageInput name='thumbnail_image' accept="image/png" onFileChange={handleFileFieldChange} />
                        </div>
                      )}
                    </div>
                    <div style={{margin: "15px 0px", display: "inline-flex"}}>
                      <div style={{margin: "0px 5px"}}>
                        <Typography variant="h6">
                          {t('foregroundColor')}
                        </Typography>
                        <CompactPicker color={passTemplate?.foreground_color} onChange={(e) => (setPassTemplate(v => ({...v, foreground_color: e.hex})))} />
                      </div>
                      <div style={{margin: "0px 5px"}}>
                        <Typography variant="h6">
                          {t('labelColor')}
                        </Typography>
                        <CompactPicker color={passTemplate?.label_color} onChange={(e) => (setPassTemplate(v => ({...v, label_color: e.hex})))} />
                      </div>
                    </div>
                    { !!!('background_image' in passTemplate && passTemplate?.background_image) && (
                      <div style={{margin: "5px 0px"}}>
                        <Typography variant="h6">
                          {t('backgroundColor')}
                        </Typography>
                        <CompactPicker color={passTemplate?.background_color} onChange={(e) => (setPassTemplate(v => ({...v, background_color: e.hex})))} />
                      </div>
                    )}
                    <hr/>
                    <Typography variant="h4" style={{margin: "15px 0px"}}>
                      {t('fields')}
                    </Typography>
                    <div style={{display: "inline-flex"}}>
                      { 'primary_field' in passTemplate && (
                        <div style={{margin: "15px"}}>
                          <Typography variant="h6">
                            {t('primaryField')}
                          </Typography>
                          <FieldEdit field={passTemplate?.primary_field} setField={setField('primary_field')} companyId={realm?.id} />
                        </div>
                      )}
                      { 'transit_type' in passTemplate && (
                        <>
                          <div style={{margin: "15px"}}>
                            <Typography variant="h6">
                              {t('originField')}
                            </Typography>
                            <FieldEdit field={passTemplate?.origin_field} setField={setField('origin_field')} companyId={realm?.id} />  
                          </div>
                          <div style={{margin: "15px"}}>
                            <Typography variant="h6">
                              {t('destinationField')}
                            </Typography>
                            <FieldEdit field={passTemplate?.destination_field} setField={setField('destination_field')} companyId={realm?.id} />
                          </div>
                        </>
                      )}
                    </div>
                    <hr/>
                    <div style={{display: "inline-flex"}}>
                      <div style={{margin: "15px"}}>
                        <Typography variant="h6">
                          {t('headerFields')}
                        </Typography>
                        <FieldListEdit fields={passTemplate.header_fields} setFields={setFields('header_fields')} max={3} companyId={realm?.id} />
                      </div>
                      <div style={{margin: "15px"}}>
                        <Typography variant="h6">
                          {t('secondaryFields')}
                        </Typography>
                        <FieldListEdit fields={passTemplate.secondary_fields} setFields={setFields('secondary_fields')} max={4} companyId={realm?.id} />
                      </div>
                      <div style={{margin: "15px"}}>
                        <Typography variant="h6">
                          {t('auxiliaryFields')}
                        </Typography>
                        <FieldListEdit fields={passTemplate.auxiliary_fields} setFields={setFields('auxiliary_fields')} max={4} companyId={realm?.id} />
                      </div>
                      <div style={{margin: "15px"}}>
                        <Typography variant="h6">
                          {t('backFields')}
                        </Typography>
                        <FieldListEdit fields={passTemplate.back_fields} setFields={setFields('back_fields')} companyId={realm?.id} />
                      </div>
                    </div>
                    <hr/>
                    <div style={{display: "inline-flex"}}>
                      <div style={{margin: "15px"}}>
                        <Typography variant="h6">
                          {t('ibeaconList')}
                        </Typography>
                        <IBeaconListEdit ibeacons={passTemplate.ibeacons} setIBeacons={setFields('ibeacons')} />
                      </div>
                      <div style={{margin: "15px"}}>
                        <Typography variant="h6">
                          {t('locationList')}
                        </Typography>
                        <LocationListEdit locations={passTemplate.locations} setLocations={setFields('locations')} />
                      </div>
                      <div style={{margin: "15px"}}>
                        <Typography variant="h6">
                          {t('barcodeList')}
                        </Typography>
                        <BarcodeListEdit barcodes={passTemplate.barcodes} setBarcodes={setFields('barcodes')} />
                      </div>
                    </div>
                    <hr/>
                    <Button>{t('saveTemplate')}</Button>
                  </form>
                </InputsWrapper>
              </div>
            </div>
          </div>
        </LocalizationProvider>
      );
    }
  }
  return EditTemplate;
}