import styled from "styled-components";


export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TableCell = styled.td`
  border: 1px solid #ddd;
  padding: 4px;
`;

export const TableHead = styled.thead`
  border: 1px solid #9a9a9a;
  background-color: #9a9a9a;
  padding: 4px;
`;


export const TableRow = styled.tr`
  &:nth-child(even) {background-color: #f2f2f2;}
`;

export const TableBody = styled.tbody`
  border: 1px solid #9a9a9a;
  padding: 4px;
`;

