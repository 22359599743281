import styled from 'styled-components';


export const ContainerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center !important;

  padding-top: 40px;
  a {
    text-decoration: none;
    color: #ffffff;
  }
`;

export const TemplateBlock = styled.div`
  margin-top: 30px;
`;

export const TemplateRendererWrapper = styled.div`
  display: flex;
  margin: 10px 0px;
  justify-content: center;
  align-items: center;
`;

export const InputsWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  
  border: 1px solid #f0f0f0;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  margin-bottom: 30px;
  background-color: #f0f0f0;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  margin: 30px;
`;