import { useGenerateStoreCardPassMutation, useRetrieveStoreCardQuery } from 'services/storeCards';
import { StoreCard } from '..';
import { createPassComponent } from "components/reusable/pass";
import { StoreCardInputs } from 'types';


export const AdminCreateStoreCard = createPassComponent<StoreCardInputs>({
  isAdminView: true,
  TemplateRenderer: StoreCard,
  retrievePassTemplateHook: useRetrieveStoreCardQuery,
  createPassHook: useGenerateStoreCardPassMutation,
  i18nNamespace: 'storeCard',
  successUrl: '/backoffice/store-cards/',
})

export const CreateStoreCard = createPassComponent<StoreCardInputs>({
  isAdminView: false,
  TemplateRenderer: StoreCard,
  retrievePassTemplateHook: useRetrieveStoreCardQuery,
  createPassHook: useGenerateStoreCardPassMutation,
  i18nNamespace: 'storeCard',
  successUrl: '/',
})
