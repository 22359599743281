import { useRetrieveBoardingPassQuery, useDeleteBoardingPassMutation, useDeleteBoardingPassTemplateMutation } from 'services/boardingPasses';
import { BoardingPass } from '../boardingPass';
import { createTemplateDetails } from 'components/reusable/template/details';


export const BoardingPassDetails = createTemplateDetails({
  TemplateRenderer: BoardingPass,
  retrieveHook: useRetrieveBoardingPassQuery,
  deletePassHook: useDeleteBoardingPassMutation,
  deleteTemplateHook: useDeleteBoardingPassTemplateMutation,
  baseUrl: '/boarding-passes/',
  i18nNamespace: 'boardingPass',
})