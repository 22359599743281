import { useUpdateBoardingPassMutation, useRetrieveBoardingPassQuery } from 'services/boardingPasses';
import { BoardingPass } from '../boardingPass';
import { createPassUpdate } from 'components/reusable/pass';


export const UpdateBoardingPass = createPassUpdate({
  TemplateRenderer: BoardingPass,
  retrieveTemplateHook: useRetrieveBoardingPassQuery,
  updatePassHook: useUpdateBoardingPassMutation,
  i18nNamespace: 'boardingPass',
  baseUrl: '/boarding-passes/',
})
