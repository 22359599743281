import { useRetrieveStoreCardQuery, useUpdateStoreCardMutation } from 'services/storeCards';
import { StoreCard } from 'components/passes/storeCard';
import { createTemplateEdit } from 'components/reusable/template';
import { StoreCardInputs } from 'types';


export const EditStoreCardTemplate = createTemplateEdit<StoreCardInputs>({
  TemplateRenderer: StoreCard,
  retrieveHook: useRetrieveStoreCardQuery,
  patchHook: useUpdateStoreCardMutation,
  i18nNamespace: 'storeCard',
  baseUrl: '/store-cards/',
});