import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'types';
import { Input, InputGroup, Label } from 'components/reusable/inputs';


const AirportField = ({ field, onChangeCallback }: { field: Field, onChangeCallback: Function }) => {
  const { t } = useTranslation();
  const [inputs, setInputs] = useState({
    label: field.label,
    value: field.value,
  });

  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((currentInput) => ({...currentInput, [name]: value}));
  }

  useEffect(() => {
    onChangeCallback({[field.key]: inputs});
  }, [inputs, field]);

  return (
    <InputGroup>
      <InputGroup column>
        <Label htmlFor={field.key + field.id}>{field.label}</Label>
        <Input id={field.key + field.id} name='label' defaultValue={ inputs?.['label'] } onChange={handleChange} />
      </InputGroup>
      <InputGroup column>
        <Label htmlFor={field.key}>{field.label}: {t('boardingPass:value')}</Label>
        <Input id={field.key} name='value' defaultValue={ inputs?.['value'] } onChange={handleChange} />
      </InputGroup>
    </InputGroup>
  );
}

export default AirportField;