import type { EventTicket, CreatedPass } from '../types';
import { api } from './api';

const RESOURCE_PATH = `/templates/event_tickets/`;

export const EventTicketApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listEventTicketTemplates: builder.query<EventTicket[], void>({
      query: () => RESOURCE_PATH,
      providesTags: (result) =>
      result
        ? [
            ...result.map(({ id }) => ({ type: 'EventTickets' as const, id })), { type: 'EventTickets', id: 'LIST' }]
        : [{ type: 'EventTickets', id: 'LIST' }],
    }),
    createEventTicketTemplate: builder.mutation<EventTicket, {}>({
      query: (data: object) => ({
        url: RESOURCE_PATH,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      }),
      invalidatesTags: [{ type: 'EventTickets', id: 'LIST' }],
    }),
    retrieveEventTicketTemplate: builder.query<EventTicket, string>({
      query: (id) =>  RESOURCE_PATH + `${id}/`,
    }),
    deleteEventTicketTemplate: builder.mutation<EventTicket, string>({
      query: (id) => ({
        url: RESOURCE_PATH + `${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: (res, err, id) => [{ type: 'EventTickets', id }, { type: 'EventTickets', id: 'LIST' }],
    }),
    updateEventTicketTemplate: builder.mutation<EventTicket, {id: string, updatedPassTemplate: object}>({
      query: (data) => ({
        url: RESOURCE_PATH + `${data.id}/`,
        method: 'PATCH',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data.updatedPassTemplate),
      }),
      invalidatesTags: (res, err, data) => [{ type: 'EventTickets', id: data.id }, { type: 'EventTickets', id: 'LIST' }],
    }),
    generateEventTicket: builder.mutation<CreatedPass, {id: string, fieldsData: object}>({
      query: (data) => ({
        url: RESOURCE_PATH + `${data.id}/create_pass/`,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data.fieldsData),
      }),
      invalidatesTags: (res, err, data) => [{ type: 'EventTickets', id: data.id }, { type: 'EventTickets', id: 'LIST' }],
    }),
    updateEventTicket: builder.mutation<CreatedPass, {id: string, fieldsData: object}>({
      query: (data) => ({
        url: RESOURCE_PATH + `${data.id}/update_pass/`,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data.fieldsData),
      }),
      invalidatesTags: (res, err, data) => [{ type: 'EventTickets', id: data.id }, { type: 'EventTickets', id: 'LIST' }],
    }),
    deleteEventTicket: builder.mutation<EventTicket, {templateId: string, passId: string}>({
      query: (data) => ({
        url: RESOURCE_PATH + `${data.templateId}/delete_pass/`,
        method: 'DELETE',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({"pass_id": data.passId}),
      }),
      invalidatesTags: (res, err, data) => [{ type: 'EventTickets', id: data.templateId }, { type: 'EventTickets', id: 'LIST' }],
    }),
    sendEventTicketPush: builder.mutation<EventTicket, {templateId: string, passId: string, message: string}>({
      query: (data) => ({
        url: RESOURCE_PATH + `${data.templateId}/send_push/`,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({"pass_id": data.passId, "message": data.message}),
      }),
    }),
  }),
})

// Export hooks for usage in functional components, which are
export const { useListEventTicketTemplatesQuery, useCreateEventTicketTemplateMutation, useRetrieveEventTicketTemplateQuery,
               useUpdateEventTicketTemplateMutation, useDeleteEventTicketTemplateMutation,
               useGenerateEventTicketMutation, useUpdateEventTicketMutation, useDeleteEventTicketMutation,
               useSendEventTicketPushMutation } = EventTicketApi