import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/reusable/buttons';
import { useAddVisitMutation, useRemoveVisitMutation, useRenewMembershipMutation, useGetMembershipTemplateQuery, useLazyGetMembershipBySerialQuery } from 'services/scenarios';
import { useScenarioContext } from 'contexts/scenarioContext';
import { Table, TableBody, TableRow, TableCell } from 'components/reusable/tables';
import { create } from 'domain';
import { Membership } from 'types';
import { ScenarioTypeEnum } from 'constants/enums';
import { toast } from 'react-toastify';
import styled from 'styled-components';


const MembershipActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: #f5f5f5;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin: auto 0;

  button {
    margin: 0 10px;
  }
`;

const ErrorMessage = styled.p`
  color: red;

  &:before {
    content: "Error: ";
    font-weight: bold;
  }
`;


type ErrorResponse = {
  status: number;
  data: {
    reason: string;
  }
};


export const MembershipActions = () => {

  const { createdPass, passTemplate, scenarioId } = useScenarioContext();
  const { t } = useTranslation();
  const [membership, setMembership] = useState<Membership | null>(null);
  const [addVisit, { isSuccess: isAddSuccess, isLoading: isAddLoading, isError: isAddError, error: addError }] = useAddVisitMutation();
  const [removeVisit, { isSuccess: isRemoveSuccess, isLoading: isRemoveLoading, isError: isRemoveError, error: removeError }] = useRemoveVisitMutation();
  const [renewMembership, { isSuccess: isRenewSuccess, isLoading: isRenewLoading, isError: isRenewError, data: renewData, error: renewError }] = useRenewMembershipMutation();
  const [getCouponBySerial, { isSuccess: isGetBySerialSuccess, isError: isGetError, data: membershipData }] = useLazyGetMembershipBySerialQuery();

  useEffect(() => {
      if (isAddSuccess) {
        toast.success("Visit added");
      } else if (isAddError) {
        const error = addError as ErrorResponse;
        const errorMessage = error.status === 403 ? t(error.data.reason) : t('visitNotAllowed');
        toast.error(errorMessage, { autoClose: 5000 });
      }
      if (createdPass?.serial_number && !membership) {
        getCouponBySerial(String(createdPass?.serial_number));
      }
    }, [isAddSuccess, isAddError, createdPass?.serial_number]);

  useEffect(() => {
    if (isRenewSuccess) {
      toast.success("Renew success");
    } else if (isRenewError) {
      const error = renewError as ErrorResponse;
      const errorMessage = error.status === 403 ? t(error.data.reason) : t('renewNotAllowed');
      toast.error(errorMessage, { autoClose: 5000 });
    }
  }, [isRenewSuccess, isRenewError]);
  
  useEffect(() => {
    if (isRemoveSuccess) {
      toast.success("Visit removed");
    } else if (isRemoveError) {
      const error = removeError as ErrorResponse;
      const errorMessage = error.status === 403 ? t(error.data.reason) : t('removeNotAllowed');
      toast.error(errorMessage, { autoClose: 5000 });
    }
  }, [isRemoveSuccess, isRemoveError]);

  useEffect(() => {
    if (isGetBySerialSuccess) {
      setMembership(membershipData as Membership);
      // toast.success("Pass found!");
    } else if (isGetError) {
      toast.error("Getting membership pass is failed", { autoClose: 5000 });
    }
  }, [isGetBySerialSuccess, isGetError]);

  return (
    <MembershipActionsContainer>
      <h2>{t('membershipActions')}</h2>
      {!createdPass?.serial_number ? (
        <p>{t('noPassSelected')}</p>
      ) : (
        (scenarioId == membership?.template) ? (
          <>
            <ButtonContainer>
              <Button onClick={() => addVisit(String(createdPass?.serial_number))} disabled={!Boolean(createdPass?.serial_number) || isAddLoading}>{t('addVisit')}</Button>
              <Button onClick={() => removeVisit(String(createdPass?.serial_number))} disabled={!Boolean(createdPass?.serial_number) || isRemoveLoading}>{t('removeVisit')}</Button>
              <Button onClick={() => renewMembership(String(createdPass?.serial_number))} disabled={!Boolean(createdPass?.serial_number) || isRenewLoading}>{t('renewMembership')}</Button>
            </ButtonContainer>
          </>
        ) : (
          <>
            <p>{t('wrongTemplateOrCardSelected')}</p>
          </>
        )
      )}
    </MembershipActionsContainer>
  )
}


export const MembershipState = () => {

  const { t } = useTranslation();
  const { scenarioId, scenarioObject, setScenarioObject } = useScenarioContext();

  const {data, isLoading, isSuccess} = useGetMembershipTemplateQuery(String(scenarioId));

  useEffect(() => {
    if (isSuccess) {
      setScenarioObject(data);
    }
  }, [isSuccess]);

  return (
    <>
      <h2>{t('membershipState')}</h2>
      {isLoading && <p>{t('loading')}</p>}
      {isSuccess && (
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>{t('membershipName')}</TableCell>
              <TableCell>{scenarioObject?.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('membershipDescription')}</TableCell>
              <TableCell>{scenarioObject?.description}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('membershipTariff')}</TableCell>
              <TableCell>{scenarioObject?.tariff}</TableCell>
            </TableRow>
            { scenarioObject?.tariff == ScenarioTypeEnum.LIMITED_VISITS && (
              <>
                <TableRow>
                  <TableCell>{t('membershipMaxVisits')}</TableCell>
                  <TableCell>{scenarioObject?.max_visits}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t('membershipDuration')}</TableCell>
                  <TableCell>{scenarioObject?.duration}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t('membershipStartingDay')}</TableCell>
                  <TableCell>{scenarioObject?.starting_day}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t('membershipContractRenewalDay')}</TableCell>
                  <TableCell>{scenarioObject?.contract_renewal_day}</TableCell>
                </TableRow>
              </>
            )}
            { scenarioObject?.tariff == ScenarioTypeEnum.LIMITED_TIME && (
              <>
                <TableRow>
                  <TableCell>{t('membershipWeekdays')}</TableCell>
                  <TableCell>{scenarioObject?.allowed_weekdays}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t('membershipStartTime')}</TableCell>
                  <TableCell>{scenarioObject?.starting_time}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t('membershipEndTime')}</TableCell>
                  <TableCell>{scenarioObject?.ending_time}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t('membershipStartingDay')}</TableCell>
                  <TableCell>{scenarioObject?.starting_day}</TableCell>
                </TableRow>
              </>
            )}
            {/* <TableRow>
              <TableCell>{t('membershipVisitsField')}</TableCell>
              <TableCell>{scenarioObject?.visits_field}</TableCell>
            </TableRow> */}
            <TableRow>
              <TableCell>{t('membershipPrice')}</TableCell>
              <TableCell>{scenarioObject?.price + ' ' + scenarioObject?.currency}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </>
  )
}