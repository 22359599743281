import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { StoreCard } from '../../types';


export interface StoreCardState {
    passes: StoreCard[];
    selectedPass: StoreCard | null;
    status: 'idle' | 'loading' | 'failed';
}

const initialState: StoreCardState = {
    passes: [],
    selectedPass: null,
    status: 'loading',
};


export const StoreCardsSlice = createSlice({
  name: 'storeCards',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    received: (state, action: PayloadAction<StoreCard[]>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.passes = action.payload;
    },
    created: (state, action: PayloadAction<StoreCard>) => {
      state.passes.concat(action.payload);
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    deleted: (state, action: PayloadAction<StoreCard>) => {
      let index = state.passes.indexOf(action.payload)
      state.passes = state.passes.splice(index, 1);
    },
  },
});

export const { received, created, deleted } = StoreCardsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectPasses = (state: RootState) => state.storeCards.passes.values;

export default StoreCardsSlice.reducer;
