import styled, { css } from "styled-components";
import QRCode from "react-qr-code";
import { CreatedPass, Barcode as BarcodeType } from "types";
import Barcode from 'react-barcode';
import { useTranslation } from 'react-i18next';
import { TemplateType } from "interfaces";
import { aztec, pdf417, code128, toGif } from 'utils/barcode';




export const TemplateRendererWrapper = styled.div`
  margin: 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`;


interface LabelProps {
  readonly color: string | undefined;
}

const GenericFieldLabel = css<LabelProps>`
  color: ${props => props.color || null};
  display: flex;
  justify-content: space-between;
  letter-spacing: 0.5px;
  font-size: 9px;
  margin-bottom: 0px;
  white-space: nowrap;
  font-weight: bold;

  @media (max-width: 1200px) {
    font-size: 8px;
  }
`

const GenericFieldValue = css`
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-bottom: 0px;

  @media (max-width: 1200px) {
    font-size: 10px;
  }
`


export const PassContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`

export const Pass = styled.div<{backgroundColor: string | undefined, foregroundColor: string | undefined, backgroundImg?: string | undefined}>`
  padding: 2px;
  width: 250px;
  height: 370px;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.backgroundImg ? 'transparent' : props.backgroundColor};
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  color: ${props => props.foregroundColor || "#bbbbbb"};
  box-sizing: border-box;
  border-radius: 10px;
  position: relative;
  z-index: 0;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: inherit;
    height: inherit;
    background-image: ${props => props.backgroundImg ? `url(${props.backgroundImg})` : 'none'}; // Set the background image if available
    background-size: cover; // Stretch the background image to cover the entire container
    background-repeat: no-repeat; // Do not repeat the background image
    filter: ${props => props.backgroundImg ? 'blur(3px)' : 'none'}; // Apply blur only when a background image is present
    z-index: -1; // Place the pseudo-element behind the content
  }

  @media (max-width: 1200px) {
    width: 200px;
    height: 296px;
  }
`

export const PassBackFields = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: auto;
  padding: 10px;
  width: 95%;
  height: 95%;
  background-color: #ffffff;
  border-radius: 8px;
  color: #000000;
  align-items: top;
  justify-content: flex-start;
`

export const PassBackField = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-self: center;
  width: 100%;
  flex-basis: fit-content; /* Adjust the flex-basis as needed */

  // insert top border starting from the second row
  &:nth-child(n+2) {
    border-top: 1px solid #000000;
  }
`

export const PassBackLabel = styled.span`
  ${GenericFieldLabel}
  color: #000000;
  margin: auto 20px auto 0px;
  flex-basis: max-content;
`

export const PassBackValue = styled.span`
  ${GenericFieldValue}
  color: #000000;
  margin-left: auto;

`

export const PassBackAttributedValue = styled.p`
  ${GenericFieldValue}
  color: #000000;
  margin-left: auto;
  font-size: 10px;
  margin-top: 0px;
  margin-bottom: 0px;

  div > a {
    color: #0000FF;
    text-decoration: none;
  }
`

// Add this style to remove right margin when PassBackValue wraps to the next line
PassBackValue.defaultProps = {
  className: 'passBackValue',
};

// Add this style to remove right margin for the last item in each row
PassBackField.defaultProps = {
  className: 'passBackField',
};

export const PassHeader = styled.div`
  height: 13%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 6px 6px 0px 6px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  filter: none;
  @media (max-width: 1200px) {
    padding: 0px;
  }
`

export const PassHorizontalRule = styled.hr<{labelColor: string | undefined}>`
  height: 2px;
  border: 1px solid ${props => props.labelColor || "#000000"};
  margin: 5px;
`

export const PassBody = styled.div`
  display: flex;
  padding: 0px 10px 10px 10px;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 45%;
  justify-content: start;
  @media (max-width: 1200px) {
    padding: 0px 6px 6px 6px;
  }
`

export const PassBodyTop = styled.div<{stripImage?: string | undefined}>`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  height: 70px;
  margin-bottom: 10px;
  position: relative;
`

export const PassBodyTopStripImageContainer = styled.div`
  flex-direction: row;
  box-sizing: border-box;
  position: absolute;
  top: -17px;
  left: -12px;
  width: 250px;
  height: 100px;
  z-index: -1;
  /* box-shadow: 10px 0 10px -10px rgba(0, 0, 0, 0.2), -10px 0 10px -10px rgba(0, 0, 0, 0.2); */

  @media (max-width: 1200px) {
    top: -17px;
    left: -11px;
    width: 200px;
    height: 80px;
  }
`

export const PassBodyTopStripImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`

export const PassFooterImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  height: 12px;

  @media (max-width: 1200px) {
    height: 10px;
  }
`

export const PassFooterImage = styled.img`
  width: 100%;
  object-fit: scale-down;
`

export const PassBodyTopFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 70%;
`

export const PassBodyBottom = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  text-overflow: clip;
  white-space: nowrap;
`

export const PassFooter = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  justify-content: center;
  margin-top: auto;
`

export const PassLogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  max-width: 120px;
  max-height: 40px;

  @media (max-width: 1200px) {
    max-width: 96px;
    max-height: 30px;
    }
`

export const PassLogo = styled.img`
  max-width: 100%;
  max-height: 100%;

  @media (max-width: 1200px) {
    height: 30px;
    max-height: 30px;
  }
`;

export const PassLogoText = styled.div`
  display: inline-flex;
  font-size: 16px;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @media (max-width: 1200px) {
    font-size: 14px;
  }
`

export const PassOriginField = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 42%;
`

export const PassDestinationField = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 42%;
  align-items: flex-end;
`

export const PassSecondaryFields = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 5px;
  width: 100%;
`

export const PassSecondaryField = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  &:not(:last-child) {
    margin-right: 10px;
  }
`

export const PassAuxiliaryFields = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 10px;
`

export const PassAuxiliaryField = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  &:not(:last-child) {
    margin-right: 10px;
  }
`

export const PassHeaderField = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  padding-left: 5px;
`

export const PassHeaderLabel = styled.span`
  ${GenericFieldLabel}
`

export const PassHeaderValue = styled.span`
  ${GenericFieldValue}
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const PassPrimaryField = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
`

export const PassPrimaryLabel = styled.span`
  ${GenericFieldLabel}
  color: ${props => props.color || null};
  margin-bottom: 0px;
  overflow-wrap: break-word;
  hyphens: auto;
  width: 200px;

  @media (max-width: 1200px) {
    font-size: 8px;
  }
`

export const PassPrimaryValue = styled.span`
  ${GenericFieldValue}
  font-size: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 225px;
  height: 20px;
  

  @media (max-width: 1200px) {
    font-size: 14px;
    width: 180px;
  }
`

export const PassOriginLabel = styled.span`
  ${GenericFieldLabel}
`

export const PassOriginValue = styled.span`
  ${GenericFieldValue}
  font-size: 22px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const PassDestinationLabel = styled.span`
  ${GenericFieldLabel}
  width: 100%;
  justify-content: flex-end;
`

export const PassDestinationValue = styled.span`
  ${GenericFieldValue}
  font-size: 22px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  justify-content: flex-end;
`

export const PassSecondaryLabel = styled.span`
  ${GenericFieldLabel}
`

export const PassSecondaryValue = styled.span`
  ${GenericFieldValue}
  font-size: 12px;
`

export const PassAuxiliaryLabel = styled.span`
  ${GenericFieldLabel}
`

export const PassAuxiliaryValue = styled.span`
  ${GenericFieldValue}
  font-size: 12px;
`

export const PassThumbnailContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 25%;
  height: 100%;
  margin-left: auto;
`

export const PassThumbnail = styled.img`
  height: fit-content;
  background: #aaaaaa;
  min-width: 50px;
`

export const PassTransitTypeIcon = styled.div<{ iconColor: string | undefined }>`
  align-self: center;
  width: 16%;

  @media (max-width: 1200px) {
    width: 40px;
  }

  svg {
    width: 100%;
    fill: ${props => props.iconColor || "black"};
  }
`

export const PassRectBarcode = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  height: 72px;
  margin: 0px 10px 5px 10px;
  background-color: #ffffff;
  flex-direction: column;

  svg {
    max-height: 60px;
    width: 100%;
  }

  p {
    font-size: 10px;
    margin-top: 5px;
  }
`

export const PassSquareBarcode = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 5px;
  align-items: center;
  flex-direction: column;
  margin: 0px auto 5px;


  @media (max-width: 1200px) {
    margin: 6px auto;
    padding: 6px;
    border-radius: 3px;
    height: 30%;
  }
`

const BarcodeSpan = styled.span`
  display: flex;
  font-size: 10px;
  margin-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #000000;
  
  @media (max-width: 1200px) {
    font-size: 8px;
  }
`


export function PassBarcode({ barcodes, createdPass }: { barcodes: BarcodeType[] | undefined, createdPass?: CreatedPass | undefined | null }) {

  const getBarcodeValue = (barcode: any) => {
    if (barcode.message_source === "sn") {
      return createdPass?.serial_number;
    } else if (barcode.message_source === "ck") {
      return createdPass?.user_info?.[barcode.message_source_key];
    } else {
      return barcode.value;
    }
  }

  const getBarcodeAltText = (barcode: any) => {
    if (barcode.alt_text) {
      return barcode.alt_text;
    } else if (barcode.use_message_as_alt) {
      return getBarcodeValue(barcode);
    } else {
      return null;
    }
  }

  const barcode = barcodes?.[0] || null;
  switch(barcode?.format) {
    case "PKBarcodeFormatPDF417": {
      return(
        <PassRectBarcode>
          <img src={pdf417(getBarcodeValue(barcode) || "WELCOME TO THE CLUB BUDDY", null, 0, 0, 'f') as unknown as string} />
          <BarcodeSpan>{getBarcodeAltText(barcode)}</BarcodeSpan>
        </PassRectBarcode>)
    }
    case "PKBarcodeFormatQR": {
      return(
        <PassSquareBarcode>
          <QRCode size={80} value={getBarcodeValue(barcode) || "WELCOME TO THE CLUB BUDDY"} />
          <BarcodeSpan>{getBarcodeAltText(barcode)}</BarcodeSpan>
        </PassSquareBarcode>)
    }
    case "PKBarcodeFormatAztec": {
      return(
        <PassSquareBarcode>
          <img src={toGif(aztec(getBarcodeValue(barcode) || "WELCOME TO THE CLUB BUDDY", null, 0), 5, undefined, 0, [[255,255,255],[0,0,0]], 2) as string} width={80} height={80} />
          <BarcodeSpan>{getBarcodeAltText(barcode)}</BarcodeSpan>
        </PassSquareBarcode>)
    }
    case "PKBarcodeFormatCode128": {
      return(
        <PassRectBarcode>
          <Barcode value={getBarcodeValue(barcode) || "WELCOME TO THE CLUB BUDDY"}
            margin={0}
            width={3}
            height={120}
            displayValue={false}
          />
          <p>{getBarcodeAltText(barcode)}</p>
        </PassRectBarcode>);
    }
    default: {
      return(
        <PassRectBarcode>
        </PassRectBarcode>);
    }
  }
}


export const PassBack = <T extends TemplateType>({ pass } : { pass: T | undefined }) => {
  const { t } = useTranslation();
  return (
    <PassContainer>
      <Pass backgroundColor={pass?.background_color} foregroundColor={pass?.foreground_color} backgroundImg={pass?.background_image}>
        <PassBackFields>
          { pass?.back_fields?.length ? pass.back_fields.map((field: any) => {
              return(
                <PassBackField key={field.key}>
                  <PassBackLabel color={pass?.label_color}>
                    {field.label}
                  </PassBackLabel>
                  { field.attributed_value ? (
                    <PassBackAttributedValue>
                      <div dangerouslySetInnerHTML={{__html: field.attributed_value}} />
                    </PassBackAttributedValue>
                  ) : ( 
                    <PassBackValue>
                      {field.value}
                    </PassBackValue>
                  )}
                </PassBackField>
              )
            }) : (
              <span>{t("pass:noBackFields")}</span>
            )
          }
        </PassBackFields>
      </Pass>
    </PassContainer>
  )
}