import type { GenericPass, CreatedPass } from '../types';
import { api } from './api';

const RESOURCE_PATH = `/templates/generic_passes/`;

export const GenericPassApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listGenericPassTemplates: builder.query<GenericPass[], void>({
      query: () => RESOURCE_PATH,
      providesTags: (result) =>
      result
        ? [
            ...result.map(({ id }) => ({ type: 'GenericPasses' as const, id })),
            { type: 'GenericPasses', id: 'LIST' },
          ]
        : [{ type: 'GenericPasses', id: 'LIST' }],
    }),
    createGenericPassTemplate: builder.mutation<GenericPass, {}>({
      query: (data: object) => ({
        url: RESOURCE_PATH,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      }),
      invalidatesTags: [{ type: 'GenericPasses', id: 'LIST' }],
    }),
    retrieveGenericPassTemplate: builder.query<GenericPass, string>({
      query: (id) =>  RESOURCE_PATH + `${id}/`,
      providesTags: (res, err, id) => [{ type: 'GenericPasses', id }],
    }),
    deleteGenericPassTemplate: builder.mutation<GenericPass, string>({
      query: (id) => ({
        url: RESOURCE_PATH + `${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'GenericPasses', id: 'LIST' }],
    }),
    updateGenericPassTemplate: builder.mutation<GenericPass, {id: string, updatedPassTemplate: object}>({
      query: (data) => ({
        url: RESOURCE_PATH + `${data.id}/`,
        method: 'PATCH',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data.updatedPassTemplate),
      }),
      invalidatesTags: (res, err, data) => [{ type: 'GenericPasses', id: data.id }],
    }),
    generateGenericPass: builder.mutation<CreatedPass, {id: string, fieldsData: object}>({
      query: (data) => ({
        url: RESOURCE_PATH + `${data.id}/create_pass/`,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data.fieldsData),
      }),
      invalidatesTags: (res, err, data) => [{ type: 'GenericPasses', id: data.id }],
    }),
    updateGenericPass: builder.mutation<CreatedPass, {id: string, fieldsData: object}>({
      query: (data) => ({
        url: RESOURCE_PATH + `${data.id}/update_pass/`,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data.fieldsData),
      }),
      invalidatesTags: (res, err, data) => [{ type: 'GenericPasses', id: data.id }],
    }),
    deleteGenericPass: builder.mutation<GenericPass, {templateId: string, passId: string}>({
      query: (data) => ({
        url: RESOURCE_PATH + `${data.templateId}/delete_pass/`,
        method: 'DELETE',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({"pass_id": data.passId}),
      }),
      invalidatesTags: (res, err, data) => [{ type: 'GenericPasses', id: data.templateId }, { type: 'GenericPasses', id: 'LIST' }],
    }),
    sendGenericPassPush: builder.mutation<GenericPass, {templateId: string, passId: string, message: string}>({
      query: (data) => ({
        url: RESOURCE_PATH + `${data.templateId}/send_push/`,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({"pass_id": data.passId, "message": data.message}),
      }),
    }),
  }),
})

// Export hooks for usage in functional components, which are
export const { useListGenericPassTemplatesQuery, useCreateGenericPassTemplateMutation, useRetrieveGenericPassTemplateQuery,
               useUpdateGenericPassTemplateMutation, useDeleteGenericPassTemplateMutation,
               useGenerateGenericPassMutation, useUpdateGenericPassMutation, useDeleteGenericPassMutation,
               useSendGenericPassPushMutation } = GenericPassApi