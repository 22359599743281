import { useGenerateCouponPassMutation, useRetrieveCouponQuery } from 'services/coupons';
import { Coupon } from '..';
import { createPassComponent } from 'components/reusable/pass';
import { CouponInputs } from 'types';


export const AdminCreateCoupon = createPassComponent<CouponInputs>({
  isAdminView: true,
  TemplateRenderer: Coupon,
  successUrl: '/backoffice/coupons/',
  i18nNamespace: 'coupon',
  retrievePassTemplateHook: useRetrieveCouponQuery,
  createPassHook: useGenerateCouponPassMutation,
});


export const CreateCoupon = createPassComponent<CouponInputs>({
  isAdminView: false,
  TemplateRenderer: Coupon,
  successUrl: '/',
  i18nNamespace: 'coupon',
  retrievePassTemplateHook: useRetrieveCouponQuery,
  createPassHook: useGenerateCouponPassMutation,
});