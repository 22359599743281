import { User } from 'types';
import { BASE_URL, CLIENT_ID, CLIENT_SECRET } from 'constants/api';
import { api } from './api';


// Define a service using a base URL and expected endpoints
export const usersApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
        query: ({ username, password }) => ({
          url: `/auth/token`,
          method: 'POST',
          body: { username, password, client_id: CLIENT_ID, client_secret: CLIENT_SECRET, grant_type: "password" },
        }),
      }),
    logout: builder.mutation({
        query: () => ({
          url: `${BASE_URL}/auth/logout/`,
          method: 'GET',
        }),
      }),
    refreshToken: builder.mutation({
        query: ({ refreshToken }) => ({
          url: `/auth/token`,
          method: 'POST',
          body: {
            client_id: CLIENT_ID,
            client_secret: CLIENT_SECRET,
            grant_type: "refresh_token",
            refresh_token: refreshToken,
          },
        }),
      }),
    getUserData: builder.query<User, void>({
      query: () => `/users/iam/`,
    }),
  }),
})

export const { useLoginMutation, useLogoutMutation, useRefreshTokenMutation, useGetUserDataQuery } = usersApiSlice