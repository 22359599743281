import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { Authorization, ServerAuthorization, User, CompanyProfile } from 'types';
import { usersApiSlice } from 'services/users';



export interface AuthState {
    authorization: Authorization | null;
    user: User | null;
    status: 'idle' | 'loading' | 'failed';
    selected_realm: CompanyProfile | null;
}

const initialState: AuthState = {
    authorization: (localStorage.getItem('accessToken') ? 
      ({ accessToken: localStorage.getItem('accessToken'),
        refreshToken: localStorage.getItem('refreshToken'),
        tokenType: localStorage.getItem('tokenType'),
      }) as Authorization : null),
    user: null,
    status: 'idle',
    selected_realm: null,
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    tokensReceived: (state, action: PayloadAction<ServerAuthorization>) => {
      state.authorization = ({
        accessToken: action.payload.access_token,
        expiresIn: action.payload.expires_in,
        refreshToken: action.payload.refresh_token,
        scope: action.payload.scope,
        tokenType: action.payload.token_type
      }) as Authorization;
      localStorage.setItem('accessToken', action.payload.access_token);
      localStorage.setItem('refreshToken', action.payload.refresh_token);
      localStorage.setItem('tokenType', action.payload.token_type);
    },
    setUserData: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    logout: (state) => {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('tokenType');
      return {
        ...state,
        authorization: null,
        user: null,
      } as AuthState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(usersApiSlice.endpoints.login.matchFulfilled, (state, action) => {
        state.authorization = ({
          accessToken: action.payload.access_token,
          expiresIn: action.payload.expires_in,
          refreshToken: action.payload.refresh_token,
          scope: action.payload.scope,
          tokenType: action.payload.token_type
        }) as Authorization;
        localStorage.setItem('accessToken', action.payload.access_token);
        localStorage.setItem('refreshToken', action.payload.refresh_token);
        localStorage.setItem('tokenType', action.payload.token_type);
      })
      .addMatcher(usersApiSlice.endpoints.getUserData.matchFulfilled, (state, action) => {
        state.user = ({
          id: action.payload.id,
          username: action.payload.username,
          email: action.payload.email,
          is_company: action.payload.is_company,
          phone: action.payload.phone,
          realms: action.payload.realms,
          user_profile: action.payload.user_profile,
        }) as User;
        state.selected_realm = action.payload.realms?.[0] || null;
      })
      .addMatcher(usersApiSlice.endpoints.logout.matchFulfilled, (state) => {
        state.authorization = null;
        state.user = null;
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('tokenType');
      })
  },
});

export const { tokensReceived, setUserData, logout } = usersSlice.actions;

export const selectAccessToken = (state: RootState) => state.users.authorization?.accessToken
export const selectUserIsCompany = (state: RootState) => state.users.user?.is_company;
export const selectRealm = (state: RootState) => state.users.selected_realm;

export default usersSlice.reducer;
