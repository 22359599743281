import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, IconButton, Modal, Tooltip } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { Barcode, Field, IBeacon } from "types";
import './i18nNamespace';
import { Input, InputGroup, Label } from "components/reusable/inputs";
import { Select, Option, Button } from "components/reusable/buttons";


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    '& .MuiTextField-root': { m: 1, width: '25ch' },
  };


export function FieldEdit({ field, setField, companyId }) {
    const defaultFieldState = {
      owner: companyId,
      allow_modification: true,
      change_message: "Changed: %@",
      text_alignment: "PKTextAlignmentLeft",
    } as Field;
  
    const { t } = useTranslation();
    const [localField, setLocalField] = useState<Field>(defaultFieldState);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const addField = () => {
      setField(localField)
      setLocalField(defaultFieldState);
      handleClose();
    }

    const handleChange = (event: any) => {
      const name = event.target.name;
      const value = event.target.value;
      setLocalField(field => ({...field, [name]: value}));
    }

    const handleRemove = () => {
      setField()
      setLocalField(defaultFieldState);
      handleClose();
    }

    return(
      <>
        {field && (
          <div>{`${field.key} - ${field.label} - ${field.value}`}
            <Tooltip title="Filter list">
              <IconButton onClick={() => handleRemove()}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </div>
        )}
        { field ? (
          <Button type="button" onClick={handleOpen}>{t('pass:changeField')}</Button>
        ) : (
          <Button type="button" onClick={handleOpen}>{t('pass:addField')}</Button>
        )}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            component="form"
            sx={style} >
            <FormGroup>
              <FormControlLabel control={<Checkbox defaultChecked={localField?.allow_modification} onChange={(e) => setField((d: any) => ({...d, allow_modification: e.target.checked}))} />} label={t('pass:allowModification')} />
            </FormGroup>
            <FormControl fullWidth>
              <Label>{t('pass:alignment')}</Label>
              <Select
                defaultValue={localField?.text_alignment}
                name="text_alignment"
                onChange={handleChange}
              >
                <Option value={'PKTextAlignmentLeft'}>{t('pass:Left')}</Option>
                <Option value={'PKTextAlignmentCenter'}>{t('pass:Center')}</Option>
                <Option value={'PKTextAlignmentRight'}>{t('pass:Right')}</Option>
                <Option value={'PKTextAlignmentJustified'}>{t('pass:Justified')}</Option>
                <Option value={'PKTextAlignmentNatural'}>{t('pass:Natural')}</Option>
              </Select>
            </FormControl>
            <InputGroup column>
              <Label>{t('pass:key')}</Label>
              <Input required name='key' onChange={handleChange} />
            </InputGroup>
            <InputGroup column>
              <Label>{t('pass:label')}</Label>
              <Input required name='label' onChange={handleChange} />
            </InputGroup>
            <InputGroup column>
              <Label>{t('pass:value')}</Label>
              <Input required name='value' onChange={handleChange} />
            </InputGroup>
            <InputGroup column>
              <Label>{t('pass:changeMessage')}</Label>
              <Input name='change_message' onChange={handleChange} defaultValue={localField.change_message} />
            </InputGroup>
            <Button type="button" onClick={addField}>{t('pass:addField')}</Button>
          </Box>
        </Modal>
      </>
    )
  
  }

export function FieldListEdit(props: any) {

  const { fields, setFields, companyId, max, min } = props;
  const defaultFieldState = {
    owner: companyId,
    allow_modification: true,
    change_message: "Changed: %@",
    text_alignment: "PKTextAlignmentLeft",
  } as Field;
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [field, setField] = useState<Field>(defaultFieldState);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const addField = () => {
    if(max && fields?.length >= max) {
      alert(t('pass:cannotAddMore'))
      handleClose();
    } else {
      setFields([...fields, field]);
      setField(defaultFieldState);
      handleClose();
    }
  }

  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setField(field => ({...field, [name]: value}));
  }

  const handleRemoved = (index: number) => {
    if(min && fields?.length <= min) {
      alert(t('pass:cannotRemoveMore'))
      handleClose();
    } else {
      setFields([...fields.slice(0, index++), ...fields.slice(index)]);
    }
  }

  useEffect(() => {}, [fields])

  return(
    <>
      {min && fields?.length < min && (
        <div>{t('pass:needToAdd')}: {min - fields.length}</div>
      )}
      {max && (
        <div>{fields?.length} {t('pass:outOf')} {max} {t('pass:fieldsAdded')}</div>
      )}
      {fields?.map((e: any, i: number) => (
        <div key={i}>{`${e.key} - ${e.label} - ${e.value}`}
          <Tooltip title="Filter list">
            <IconButton onClick={() => handleRemoved(i)}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </div>
      ))}
      <Button type="button" onClick={handleOpen}>{t('pass:addField')}</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          component="form"
          sx={style} >
          <FormControlLabel control={<Checkbox defaultChecked={field?.allow_modification} onChange={(e) => setField(d => ({...d, allow_modification: e.target.checked}))} />} label={t('pass:allowModification')} />
          <FormControl fullWidth>
            <Label>{t('pass:alignment')}</Label>
            <Select
              defaultValue={field.text_alignment}
              name="text_alignment"
              onChange={handleChange}
            >
              <Option value={'PKTextAlignmentLeft'}>{t('pass:Left')}</Option>
              <Option value={'PKTextAlignmentCenter'}>{t('pass:Center')}</Option>
              <Option value={'PKTextAlignmentRight'}>{t('pass:Right')}</Option>
              <Option value={'PKTextAlignmentJustified'}>{t('pass:Justified')}</Option>
              <Option value={'PKTextAlignmentNatural'}>{t('pass:Natural')}</Option>
            </Select>
          </FormControl>
          <InputGroup column>
            <Label>{t('pass:key')}</Label>
            <Input required name='key' onChange={handleChange} />
          </InputGroup>
          <InputGroup column>
            <Label>{t('pass:label')}</Label>
            <Input required name='label' onChange={handleChange} />
          </InputGroup>
          <InputGroup column>
            <Label>{t('pass:value')}</Label>
            <Input required name='value' onChange={handleChange} />
          </InputGroup>
          <InputGroup column>
            <Label>{t('pass:changeMessage')}</Label>
            <Input name='change_message' defaultValue={field.change_message} onChange={handleChange} />
          </InputGroup>
          <Button type="button" onClick={addField}>{t('pass:addField')}</Button>
        </Box>
      </Modal>
    </>
  )
}

export function IBeaconListEdit(props: any) {
  const { ibeacons, setIBeacons, min } = props;
  const max: number = 10;
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [ibeacon, setIBeacon] = useState<IBeacon>({} as IBeacon);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const addIBeacon = () => {
    if(max && ibeacons.length >= max) {
      alert(t('pass:cannotAddMore'));
      handleClose();
    } else {
      setIBeacons([...ibeacons, ibeacon]);
      setIBeacon({} as IBeacon);
      handleClose();
    }
  }

  useEffect(() => {}, [ibeacons,]);

  const handleChange = (event: any) => {
    setIBeacon(ibeacon => ({...ibeacon, [event.target.name]: event.target.value}));
  }

  const handleRemoved = (index: number) => {
    if(min && ibeacons.length <= min) {
      alert(t('pass:cannotRemoveMore'))
      handleClose();
    } else {
      setIBeacons([...ibeacons.slice(0, index++), ...ibeacons.slice(index)]);
    }
  }

  return(
    <>
      {min && ibeacons?.length < min && (
        <div>{t('pass:needToAdd')}: {min - ibeacons.length}</div>
      )}
      {max && (
        <div>{ibeacons?.length} {t('pass:outOf')} {max} {t('pass:ibeaconsAdded')}</div>
      )}
      {ibeacons?.map((e: any, i: number) => (
        <div key={i}>{`${e.proximity} - ${e.major} - ${e.minor} - ${e.relevant_text}`}
          <Tooltip title="Filter list">
            <IconButton onClick={() => handleRemoved(i)}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </div>
      ))}
      <Button type="button" onClick={handleOpen}>{t('pass:addIBeacon')}</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          component="form"
          sx={style} >
          <InputGroup column>
            <Label>{t('pass:proximityUuid')}</Label>
            <Input name='proximity_uuid' onChange={handleChange} />
          </InputGroup>
          <InputGroup column>
            <Label>{t('pass:major')}</Label>
            <Input required name='major' onChange={handleChange} />
          </InputGroup>
          <InputGroup column>
            <Label>{t('pass:minor')}</Label>
            <Input required name='minor' onChange={handleChange} />
          </InputGroup>
          <InputGroup column>
            <Label>{t('pass:relevantText')}</Label>
            <Input name='relevant_text' onChange={handleChange} />
          </InputGroup>
          <Button type="button" onClick={addIBeacon}>{t('pass:addLocation')}</Button>
        </Box>
      </Modal>
    </>
  )
}

export function LocationListEdit(props: any) {
  const { locations, setLocations, min } = props;
  const max: number = 10;
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [location, setLocation] = useState<Location>({} as Location);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const addLocation = () => {
    if(max && locations.length >= max) {
      alert(t('pass:cannotAddMore'));
      handleClose();
    } else {
      setLocations([...locations, location]);
      setLocation({} as Location);
      handleClose();
    }
  }

  useEffect(() => {}, [locations,]);

  const handleChange = (event: any) => {
    setLocation(location => ({...location, [event.target.name]: event.target.value}));
  }

  const handleRemoved = (index: number) => {
    if(min && locations.length <= min) {
      alert(t('pass:cannotRemoveMore'))
      handleClose();
    } else {
      setLocations([...locations.slice(0, index++), ...locations.slice(index)]);
    }
  }

  return(
    <>
      {min && locations?.length < min && (
        <div>{t('pass:needToAdd')}: {min - locations.length}</div>
      )}
      {max && (
        <div>{locations?.length} {t('pass:outOf')} {max} {t('pass:locationsAdded')}</div>
      )}
      {locations?.map((e: any, i: number) => (
        <div key={i}>{`${e.altitude} - ${e.latitude} - ${e.longitude} - ${e.relevant_text}`}
          <Tooltip title="Filter list">
            <IconButton onClick={() => handleRemoved(i)}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </div>
      ))}
      <Button type="button" onClick={handleOpen}>{t('pass:addField')}</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          component="form"
          sx={style} >
          {/* <TextField name='altitude' type='number' InputLabelProps={{ shrink: true, }} label={t('pass:altitude')} id="outlined-size-small" size="small" margin="dense" onChange={handleChange} /> */}
          <InputGroup column>
            <Label>{t('pass:altitude')}</Label>
            <Input name='altitude' type='number' onChange={handleChange} />
          </InputGroup>
          {/* <TextField required name='latitude' InputLabelProps={{ shrink: true, }} label={t('pass:latitude')} id="outlined-size-small" size="small" margin="dense" onChange={handleChange} /> */}
          <InputGroup column>
            <Label>{t('pass:latitude')}</Label>
            <Input required name='latitude' onChange={handleChange} />
          </InputGroup>
          {/* <TextField required name='longitude' InputLabelProps={{ shrink: true, }} label={t('pass:longitude')} id="outlined-size-small" size="small" margin="dense" onChange={handleChange} /> */}
          <InputGroup column>
            <Label>{t('pass:longitude')}</Label>
            <Input required name='longitude' onChange={handleChange} />
          </InputGroup>
          {/* <TextField name='relevant_text' label={t('pass:relevantText')} id="outlined-size-small" size="small" margin="dense" onChange={handleChange} /> */}
          <InputGroup column>
            <Label>{t('pass:relevantText')}</Label>
            <Input name='relevant_text' onChange={handleChange} />
          </InputGroup>
          <Button type="button" onClick={addLocation}>{t('pass:addLocation')}</Button>
        </Box>
      </Modal>
    </>
  )
}

export function BarcodeListEdit(props: any) {
  const { barcodes, setBarcodes, min } = props;
  const defaultBarcodeState = {
    format: "PKBarcodeFormatCode128",
    use_message_as_alt: true,
    alt_text: "",
  } as Barcode;

  const max: number = 10;
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [barcode, setBarcode] = useState<Barcode>(defaultBarcodeState);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const addBarcode = () => {
    if(max && barcodes.length >= max) {
      alert(t('pass:cannotAddMore'));
      handleClose();
    } else {
      setBarcodes([...barcodes, barcode]);
      setBarcode(defaultBarcodeState);
      handleClose();
    }
  }

  useEffect(() => {}, [barcodes,]);

  const handleChange = (event: any) => {
    setBarcode(barcode => ({...barcode, [event.target.name]: event.target.value}));
  }

  const handleRemoved = (index: number) => {
    if(min && barcodes.length <= min) {
      alert(t('pass:cannotRemoveMore'))
      handleClose();
    } else {
      setBarcodes([...barcodes.slice(0, index++), ...barcodes.slice(index)]);
    }
  }

  return(
    <>
      {min && barcodes?.length < min && (
        <div>{t('pass:needToAdd')}: {min - barcodes.length}</div>
      )}
      {max && (
        <div>{barcodes?.length} {t('pass:outOf')} {max} {t('pass:barcodesAdded')}</div>
      )}
      {barcodes?.map((e: any, i: number) => (
        <div key={i}>{`${e.format} - ${e.message_source} - ${e.alt_text} - ${e.use_message_as_alt}`}
          <Tooltip title="Filter list">
            <IconButton onClick={() => handleRemoved(i)}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </div>
      ))}
      <Button type="button" onClick={handleOpen}>{t('pass:addField')}</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          component="form"
          sx={style} >
          <FormControl fullWidth size='small'>
            <Label>{t('pass:barcodeFormat')}</Label>
            <Select
              required
              defaultValue={barcode?.format}
              name="format"
              onChange={handleChange}
            >
              <Option value={'PKBarcodeFormatPDF417'}>{t('pass:PDF417')}</Option>
              <Option value={'PKBarcodeFormatQR'}>{t('pass:QR')}</Option>
              <Option value={'PKBarcodeFormatAztec'}>{t('pass:Aztec')}</Option>
              <Option value={'PKBarcodeFormatCode128'}>{t('pass:Code128')}</Option>
            </Select>
          </FormControl>
          <FormControl fullWidth size='small'>
            <Label>{t('pass:messageSource')}</Label>
            <Select
              required
              defaultValue={barcode?.message_source}
              name="message_source"
              onChange={handleChange}
            >
              <Option value={'sn'}>{t('pass:serialNumber')}</Option>
              <Option value={'ck'}>{t('pass:customKey')}</Option>
            </Select>
          </FormControl>
          { (barcode?.message_source === 'ck') && (
            <InputGroup column>
              <Label>{t('pass:messageSourceKey')}</Label>
              <Input required name='message_source_key' onChange={handleChange} />
            </InputGroup>
          )}
          <FormControlLabel control={<Checkbox onChange={(e) => setBarcode((d: any) => ({...d, use_message_as_alt: e.target.checked}))} defaultChecked />} label={t('pass:useAsAlt')} />
          { (!barcode?.use_message_as_alt) && (
            <InputGroup column>
              <Label>{t('pass:altText')}</Label>
              <Input required name='alt_text' onChange={handleChange} />
            </InputGroup>
          )}
          <Button type="button" onClick={addBarcode}>{t('pass:addField')}</Button>
        </Box>
      </Modal>
    </>
  )
}