import { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Typography, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Field } from 'types';
import {
  Input,
  Label,
  InputGroup,
} from 'components/reusable/inputs';
import TransitField from './fields';
import { Button } from 'components/reusable/buttons';
import { TemplateType } from 'interfaces';
import { ContainerWrapper, InputsWrapper, TemplateBlock, TemplateRendererWrapper } from 'components/reusable/containers';


interface CreatePassProps {
  isAdminView: boolean;
  TemplateRenderer: any;
  successUrl: string;
  i18nNamespace: string;
  retrievePassTemplateHook: (id: string) => any;
  createPassHook: () => any;
}


export const createPassComponent = <T extends TemplateType>({ isAdminView, TemplateRenderer, successUrl, i18nNamespace, createPassHook, retrievePassTemplateHook }: CreatePassProps) => {
  const CreatePass = () => {
    const { t } = useTranslation(i18nNamespace);
    const { id } = useParams();
    const [inputs, setInputs] = useState({
      field_values: {},
      barcode_values: {},
    });
    const { data, isLoading } = retrievePassTemplateHook(String(id));
    let navigate = useNavigate();
    const [generatePass, result] = createPassHook();
    const handleSubmit = (event: any) => {
      event.preventDefault();
      generatePass({ id: String(id), fieldsData: inputs });
    };

    useEffect(() => {
      if(result.isSuccess) {
        window.open(result.data.data, '_blank', 'noopener,noreferrer');
        navigate(successUrl + id + '/');
      }
    }, [result.isSuccess, result?.data?.data])

    const handleChange = (event: any) => {
      const key = event.target.id;
      const name = event.target.name;
      const value = event.target.value;
      setInputs(values => ({...values, field_values: {
        ...values.field_values, [key]: {[name]: value}
      }}));
    }

    const addBarcodeCustomValue = (event: any) => {
      const name = event.target.name;
      const value = event.target.value;
      setInputs(values => ({...values, barcode_values: {
        ...values.barcode_values, [name]: value
      }}));
    }

    const fieldsetHasUserDefinedFields = (fieldset: Field[] | undefined) => {
      return fieldset?.length !== 0 && fieldset?.some(f => f.user_defined)
    }

    const fieldIsUserDefined = (field: Field | undefined) => {
      return field?.user_defined
    }

    const injectUserInfo = (userInfo: object, passTemplate: T) => {
      if (!userInfo || !passTemplate) {
        return;
      }
      const fieldValues = userInfo?.['field_values'];
      const newPassTemplate = { ...passTemplate };
      const injectField = (field) => {
        if(fieldValues?.[field?.key]) {
          if(fieldValues[field.key].label) {
            field = { ...field, label: fieldValues[field.key].label };
          }
          if(fieldValues[field.key].value) {
            field = { ...field, value: fieldValues[field.key].value };
          }
        }
        return field;
      }
  
      newPassTemplate.header_fields = newPassTemplate?.header_fields?.map(injectField);
      newPassTemplate.secondary_fields = newPassTemplate?.secondary_fields?.map(injectField);
      newPassTemplate.auxiliary_fields = newPassTemplate?.auxiliary_fields?.map(injectField);
      newPassTemplate.back_fields = newPassTemplate?.back_fields?.map(injectField);
      newPassTemplate.origin_field = injectField(newPassTemplate.origin_field);
      newPassTemplate.destination_field = injectField(newPassTemplate.destination_field);
      return(newPassTemplate as T)
    }

    if(isLoading) {
      return(<div>{t('loading')}</div>)
    } else {
      return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ContainerWrapper>
            <div className="container text-center">
              <div className="row justify-content-start">
                <div className="col-3">
                  <TemplateBlock>
                    <TemplateRendererWrapper>
                      <TemplateRenderer pass={injectUserInfo(inputs, data)} />
                    </TemplateRendererWrapper>
                    <TemplateRendererWrapper>
                      <TemplateRenderer.Back pass={injectUserInfo(inputs, data)} />
                    </TemplateRendererWrapper>
                  </TemplateBlock>
                </div>
                <div className="col-9">
                  <InputsWrapper>
                    <form onSubmit={handleSubmit}>
                      { (data?.header_fields?.length > 0 && (isAdminView || fieldsetHasUserDefinedFields(data?.header_fields))) &&
                      <>
                        <Typography variant="h5" component="h5">{t('headerFields')}</Typography>
                        { isAdminView && data?.header_fields.map((field) => {
                            return(
                              <InputGroup column key={field.key}>
                                <Label htmlFor={field.key}>{field.label}</Label>
                                <Input
                                  type="text"
                                  id={field.key}
                                  name={'value'}
                                  defaultValue={field.value || inputs?.field_values?.[field.key]?.value}
                                  onChange={handleChange}
                                  />
                              </InputGroup>
                            )
                          })
                        }
                        { !isAdminView && data?.header_fields.filter(fieldIsUserDefined).map((field) => {
                            return(
                              <InputGroup column key={field.key}>
                                <Label htmlFor={field.key}>{field.label}</Label>
                                <Input
                                  type="text"
                                  id={field.key}
                                  name={'value'}
                                  defaultValue={field.value || inputs?.field_values?.[field.key]?.value}
                                  onChange={handleChange}
                                  />
                              </InputGroup>
                            )
                          })
                        }
                      </>
                      }
                      { (data?.origin_field && (isAdminView || fieldIsUserDefined(data?.origin_field))) && (
                          <>
                            <Typography variant="h5" component="h5">{t('originField')}</Typography>
                            { isAdminView ? (
                              <TransitField field={data?.origin_field} onChangeCallback={newValue => setInputs(v => ({...v, field_values: {...v.field_values, ...newValue }}))} />
                            ) : (
                              <InputGroup column key={data?.origin_field.key}>
                              <Label htmlFor={data?.origin_field.key}>{data?.origin_field.label}</Label>
                              <Input
                                type="text"
                                id={data?.origin_field.key}
                                name={'value'}
                                defaultValue={data?.origin_field.value || inputs?.field_values?.[data?.origin_field.key]?.value}
                                onChange={handleChange}
                                />
                            </InputGroup>
                            )}
                          </>
                        )
                      }
                      { (data?.destination_field && (isAdminView || fieldIsUserDefined(data?.destination_field))) && (
                          <>
                            <Typography variant="h5" component="h5">{t('destinationLabel')}</Typography>
                            { isAdminView ? (
                              <TransitField field={data?.destination_field} onChangeCallback={newValue => setInputs(v => ({...v, field_values: {...v.field_values, ...newValue }}))} />
                            ) : (
                              <InputGroup column key={data?.destination_field.key}>
                                <Label htmlFor={data?.destination_field.key}>{data?.destination_field.label}</Label>
                                <Input
                                  type="text"
                                  id={data?.destination_field.key}
                                  name={'value'}
                                  defaultValue={data?.destination_field.value || inputs?.field_values?.[data?.destination_field.key]?.value}
                                  onChange={handleChange}
                                  />
                              </InputGroup>
                            )}
                          </>
                        )
                      }
                      { (data?.primary_field && (isAdminView || fieldIsUserDefined(data?.primary_field))) && (
                          <>
                            <Typography variant="h5" component="h5">{t('primaryField')}</Typography>
                            <InputGroup column key={data?.primary_field.key}>
                              <Label htmlFor={data?.primary_field.key}>{data?.primary_field.label}</Label>
                              <Input
                                type="text"
                                id={data?.primary_field.key}
                                name={'value'}
                                defaultValue={data?.primary_field.value || inputs?.field_values?.[data?.primary_field.key]?.value}
                                onChange={handleChange}
                                />
                            </InputGroup>
                          </>
                        )
                      }
                      { (data?.secondary_fields?.length > 0 && (isAdminView || fieldsetHasUserDefinedFields(data?.secondary_fields))) && (
                          <>
                            <Typography variant="h5" component="h5">{t('secondaryFields')}</Typography>
                            { isAdminView && data?.secondary_fields.map((field) => {
                                return(
                                  <InputGroup column key={field.key}>
                                    <Label htmlFor={field.key}>{field.label}</Label>
                                    <Input
                                      type="text"
                                      id={field.key}
                                      name={'value'}
                                      defaultValue={field.value || inputs?.field_values?.[field.key]?.value}
                                      onChange={handleChange}
                                      />
                                  </InputGroup>
                                )
                              })
                            }
                            { !isAdminView && data?.secondary_fields.filter(fieldIsUserDefined).map((field) => {
                                return(
                                  <InputGroup column key={field.key}>
                                    <Label htmlFor={field.key}>{field.label}</Label>
                                    <Input
                                      type="text"
                                      id={field.key}
                                      name={'value'}
                                      defaultValue={field.value || inputs?.field_values?.[field.key]?.value}
                                      onChange={handleChange}
                                      />
                                  </InputGroup>
                                )
                              })
                            }
                          </>
                        )
                      }
                      { (data?.auxiliary_fields?.length > 0 && (isAdminView || fieldsetHasUserDefinedFields(data?.auxiliary_fields))) && (
                          <>
                            <Typography variant="h5" component="h5">{t('auxiliaryFields')}</Typography>
                            { isAdminView && data?.auxiliary_fields.map((field) => {
                                return(
                                  <InputGroup column key={field.key}>
                                    <Label htmlFor={field.key}>{field.label}</Label>
                                    <Input
                                      type="text"
                                      id={field.key}
                                      name={'value'}
                                      defaultValue={field.value || inputs?.field_values?.[field.key]?.value}
                                      onChange={handleChange}
                                      />
                                  </InputGroup>
                                )
                              })
                            }
                            {  !isAdminView && data?.auxiliary_fields.filter(fieldIsUserDefined).map((field) => {
                                return(
                                  <InputGroup column key={field.key}>
                                    <Label htmlFor={field.key}>{field.label}</Label>
                                    <Input
                                      type="text"
                                      id={field.key}
                                      name={'value'}
                                      defaultValue={field.value || inputs?.field_values?.[field.key]?.value}
                                      onChange={handleChange}
                                      />
                                  </InputGroup>
                                )
                            })}
                          </>
                        )
                      }
                      { (data?.back_fields?.length !== 0 && (isAdminView || fieldsetHasUserDefinedFields(data?.back_fields))) && (
                          <>
                            <Typography variant="h5" component="h5">{t('backFields')}</Typography>
                            { isAdminView && data?.back_fields.map((field) => {
                              return(
                                <InputGroup column key={field.key}>
                                  <Label htmlFor={field.key}>{field.label}</Label>
                                  <Input
                                    type="text"
                                    id={field.key}
                                    name={'value'}
                                    defaultValue={field.value || inputs?.field_values?.[field.key]?.value}
                                    onChange={handleChange}
                                    />
                                </InputGroup>
                              )
                            })}
                            { !isAdminView && data?.back_fields.filter(fieldIsUserDefined).map((field) => {
                              return(
                                <InputGroup column key={field.key}>
                                  <Label htmlFor={field.key}>{field.label}</Label>
                                  <Input
                                    type="text"
                                    id={field.key}
                                    name={'value'}
                                    defaultValue={field.value || inputs?.field_values?.[field.key]?.value}
                                    onChange={handleChange}
                                    />
                                </InputGroup>
                              )
                            })}
                          </>
                        )
                      }
                      { isAdminView && (
                        <>
                          <hr/>
                          <Typography variant="h5" component="h5">{t('specialSection')}</Typography>
                          { data?.barcodes?.length > 0 && data.barcodes.filter((b) => b.message_source === 'ck').map((barcode) => {
                            return(
                              <div style={{display: "flex", margin: "15px", justifyContent: "center"}}>
                                <InputGroup column key={barcode.id}>
                                  <Label>{t('customBarcodeValue')}</Label>
                                  <Input
                                    type="text"
                                    name={barcode.message_source_key}
                                    onChange={addBarcodeCustomValue}
                                    />
                                </InputGroup>
                              </div>
                          )})}
                          <div style={{display: "inline-flex"}}>
                            {/* <div style={{margin: "15px"}}>
                              <InputGroup column>
                                <Label>{t('serialNumber')}</Label>
                                <Input
                                  type="text"
                                  id={'serial_number'}
                                  name={'serial_number'}
                                  onChange={handleChange}
                                  />
                              </InputGroup>
                            </div> */}
                            <div style={{margin: "15px"}}>
                              <DesktopDateTimePicker label={t('relevantDate')} value={data?.relevant_date} onChange={(e) => (setInputs(values => ({...values, relevant_date: e})))} renderInput={(params) => <TextField {...params} />} />
                            </div>
                            <div style={{margin: "15px"}}>
                              <DesktopDateTimePicker label={t('expirationDate')} value={data?.expiration_date} onChange={(e) => (setInputs(values => ({...values, expiration_date: e})))} renderInput={(params) => <TextField {...params} />} />
                            </div>
                          </div>
                        </>
                      )}
                      <div>
                        <Button>{t('generatePass')}</Button>
                      </div>
                    </form>
                  </InputsWrapper>
                </div>
              </div>
            </div>
          </ContainerWrapper>
        </LocalizationProvider>
      );
    }
  }
  return CreatePass;
}