import { useRetrieveCouponQuery, usePatchCouponMutation } from 'services/coupons';
import { Coupon } from 'components/passes/coupon';
import { createTemplateEdit } from 'components/reusable/template';
import { CouponInputs } from 'types';


export const EditCouponTemplate = createTemplateEdit<CouponInputs>({
  TemplateRenderer: Coupon,
  retrieveHook: useRetrieveCouponQuery,
  patchHook: usePatchCouponMutation,
  i18nNamespace: "coupon",
  baseUrl: "/coupons/",
})